.myWssSummary__mainBox {
    width: 100%;
    height: 44.42px;
    box-shadow: 1.24923px 1.24923px 5.35782px rgba(20, 20, 20, 0.07);
    border-radius: 4.16411px;
    display: flex;
    justify-content: space-between;
    /* position: absolute; */
    background: #ffffff;
    margin-bottom: 10px ;
}

@media (min-width: 769px) {
    .myWssSummary__mainBox {
        position: initial;
    }
}

.myWssSummary__mainBoxActive {
    width: 100%;
    height: 44.42px;
    box-shadow: 1.24923px 1.24923px 5.35782px rgba(20, 20, 20, 0.07);
    border-radius: 4.16411px;
    margin-left: -92px;
    display: inline-flex;
    justify-content: space-between;
    position: inherit;
}

@media (min-width: 425px) {
    .myWssSummary__mainBoxActive {
        width: 100%;
        margin-left: 0px;
    }
}

.myWssSummary__locationBox {
    display: -webkit-inline-box;
    align-items: center;
}

.myWssSummary__location {
    display: -webkit-inline-box;
    padding: 10px 0px 10px 15px;
    align-items: center;
}

.myWssSummary__tag {
    width: 12.49px !important;
    border-top-left-radius: 4.16411px;
    border-bottom-left-radius: 4.16411px;
    height: 44.42px;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.myWssSummary__locationIcon {
    color: #3901ff;
    height: 0.9rem;
    width: 0.9rem;
}

.myWssSummary__dateBox {
    display: flex;
}

.myWssSummary__tourDate {
    display: -webkit-inline-box;
    padding: 10px 0px;
    align-items: center;
}

.myWssSummary__tourDay {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12.71625px;
    color: #acacac !important;
    margin-bottom: 0 !important;
    width: 60px;
}

.myWssSummary__tourDate p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 9.71625px;
    margin-bottom: 0;
    padding: 5px 5px !important;
    text-align: end;
}

.myWssSummary__location p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 9.71625px;
    margin-bottom: 0;
    padding: 0px 5px;
    overflow: hidden;
    max-width: 139px;
    align-content: center;
}

/* @media (max-width: 410px) {
    .myWssSummary__location p {
        max-width: 70px;
    }
} */

.myWssSummary__edit {
    align-items: center;
    padding: 10px 15px 10px 0px;
}

.myWssSummary__outerInputBox {
    width: auto;
    padding: 0.625rem;
    border-radius: 0.5rem;
    align-items: center;
    max-height: 27vh;
    overflow: scroll;
}

.myWssSummary__Outermainbox {
    background: #ffffff;
    box-shadow: 0.075rem 0.075rem 0.334rem rgba(20, 20, 20, 0.07);
    border-radius: 0.3rem;
}

.myWssSummary__heading {
    padding: 0.625rem 0px;
    color: #3901FF;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
}

.myWssSummary {
    padding: 0.625rem 1rem;
    width: 100%;
}

.myWssSummary__downloadBtn {
    background: #2CC26A !important;
    border-radius: 0.3rem !important;
    border-color: #2CC26A !important;
    color: #F9F9F9 !important;
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 0.65rem !important;
    white-space: nowrap;
    float: right;
}

.myWssSummary__divideLine {
    color: #3901ff;
    height: 0.9rem;
    width: 0.9rem;
    margin-top: 8px;
}

.myWssSummary__dropdownSelection{
    padding: 10px 10px 0px 10px;
}