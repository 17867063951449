.deleteIcons{
  align-self: center;
  margin-top: 2rem;
}
  .deleteIcons .deleteLarge{
    font-size: 3rem;
    color: #ef5464;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.deleteIcons .deleteSmall{
    font-size: 1.5rem;
    color: #FFA60033;
    margin-top: 1rem;
    }
.deleteModal__heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  font-family: 'Roboto';
  margin-bottom: 0;
}
.deleteModal__cancelBtn {
  width: 55px !important;
  height: 21px !important;
  background: #3901ff !important;
  border-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
}
.deleteModal__cancelBtnText {
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  color: #3901ff !important;


}
.deleteModal__deleteBtn {
  background: none !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
}
.deleteModal__deleteBtnText {
  font-style: normal;
  font-size: 11.5px;
  color: #fff;
}
.deleteModal__footer {
  display: grid !important;
  grid-template-columns: 50% 50% !important;
  align-items: center;
  margin-bottom: 0.7rem;
}
.deleteModal__outerBox {
  top: 14rem !important;
}
.modalData__outerBox {
  margin-top: 0.5rem;
  width: 100%;
  height: 33px;
  box-shadow: 0.925062px 0.925062px 3.96749px rgba(20, 20, 20, 0.07);
  border-radius: 3.08354px;
  background: #f3f2f1;
  align-items: center;
  display: grid;
  grid-template-columns: 38% 62%;
}
.deleteModal__location {
  display: flex;
  align-items: center;
}
.deleteModal__locationIcon {
  color: #3901ff;
  width: 11.22px;
  height: 11.22px;
  margin-left: 0.5rem;
}
.deleteModal__townName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 9.19492px;
  margin-bottom: 0px;
  margin-left: 5px;
}
.deleteModal__tourDate {
  display: grid;
  grid-template-columns: 8% 38% 4% 32%;
  align-items: center;
  gap: 4px;
}
.deleteModal__calenderIcon {
  color: #3901ff;
  width: 11.22px;
  height: 11.22px;
  margin-left: 5px;
}
.deleteModal__divideLine {
  color: #3901ff;
  width: 8.22px;
  height: 8.22px;
  margin-bottom: 11px;
  margin-left: -2px;
}

.deleteModal__tourDate p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 9.19492px;
  margin-bottom: 0;
  display: flex;
  justify-content: end;
}
.deleteModal__tourDay span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 7.19492px;
  margin-bottom: 0;
  display: flex;
  justify-content: start;
}
/* .deleteModel__outerBoxBody{
    background: #F9F9F9;
} */

.deleteModel__outerBoxBody .modalPopupSubTitle{
  display: block;
}

.upcomingTourPlans__sortForm {
  display: "grid" !important;
  grid-template-columns: "auto auto auto" !important;
  align-items: "center" !important;
  justify-content: "end" !important;
  gap: "0.2rem" !important;
}