.upcomingTourPlans__lable {
  color: #3901ff;
  font-weight: 600;
  margin-top: 0.5rem;
}
.upcomingTourPlans__filter {
  display: flex;
  justify-content: end;
  margin-top: 11px;
}
.upcomingTourPlans__filterIcon {
  position: relative;
  margin-right: 5px;
  color: #3901ff;
}
.upcomingTourPlans__filter span {
  font-family: roboto;
  font-weight: 400;
  font-size: 13.38px;
  color: #3901ff;
}
.upcomingTourPlans__date {
  font-weight: 400;
  font-size: 13.3816px;
}
.upcomingTourPlans__date p {
  white-space: nowrap;
  margin-bottom: 0px;
}

.upcomingTourPlans__outerBox {
  position: relative;
  line-height: initial;
}

.upcomingTourPlans__outerBoxActive {
  display: flex;
  margin-bottom: 10px;
}

@media (min-width: 769px) {
  .upcomingTourPlans__outerBox {
    display: flex;
    margin-bottom: 10px;
  }
}

.upcomingTourPlans__mainBox {
  width: 100%;
  height: 44.42px;
  box-shadow: 1.24923px 1.24923px 5.35782px rgba(20, 20, 20, 0.07);
  border-radius: 4.16411px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  background: #ffffff;
}

@media (min-width: 769px) {
  .upcomingTourPlans__mainBox {
    position: initial;
  }
}

.upcomingTourPlans__mainBoxActive {
  width: 100%;
  height: 44.42px;
  box-shadow: 1.24923px 1.24923px 5.35782px rgba(20, 20, 20, 0.07);
  border-radius: 4.16411px;
  margin-left: -92px;
  display: inline-flex;
  justify-content: space-between;
  position: inherit;
}

@media (min-width: 425px) {
  .upcomingTourPlans__mainBoxActive {
    width: 100%;
    margin-left: 0px;
  }
}

.upcomingTourPlans__locationIcon {
  color: #3901ff;
  height: 0.9rem;
  width: 0.9rem;
}

.upcomingTourPlans__calenderIcon {
  color: #3901ff;
  height: 0.9rem;
  width: 0.9rem;
}
.upcomingTourPlans__completed {
  height: 1.1rem;
  width: 1.1rem;
}

.upcomingTourPlans__divideLine {
  color: #3901ff;
  height: 0.9rem;
  width: 0.9rem;
}

.upcomingTourPlans__EditIcon {
  color: #2b2b2b;
  height: 0.9rem;
  width: 0.9rem;
}

.upcomingTourPlans__tag {
  width: 12.49px !important;
  border-top-left-radius: 4.16411px;
  border-bottom-left-radius: 4.16411px;
  height: 44.42px;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.upcomingTourPlans__deleteBoxActive {
  width: 100px;
  height: 44px;
  left: 138px;
  top: 239px;
  padding: 10px 10px !important;
  background: #ef5464;
  border-radius: 4.16px;
  display: flex;
  align-items: center;
  border-top-right-radius: 4.16px;
  border-bottom-right-radius: 4.16px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  visibility: visible !important;
  border: none;
}

.upcomingTourPlans__deleteBox {
  display: flex;
  padding: 10px 23px;
  visibility: hidden;
  border: none;
}

@media (min-width: 769px) {
  .upcomingTourPlans__deleteBox {
    width: 100px;
    height: 44px;
    left: 138px;
    top: 239px;
    padding: 10px 10px !important;
    background: #ef5464;
    border-radius: 4.16px;
    display: flex;
    align-items: center;
    border-top-right-radius: 4.16px;
    border-bottom-right-radius: 4.16px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    visibility: visible !important;
    border: none;
    visibility: visible;
  }

  .upcomingTourPlans__deleteBox p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 9.71625px;
    line-height: 11px;
    padding: 5px 15px;
    color: #ffffff;
    margin: 0px;
  }
}

.upcomingTourPlans__deleteBoxActive p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 9.71625px;
  line-height: 11px;
  padding: 5px 15px;
  color: #ffffff;
  margin: 0px;
}

.upcomingTourPlans__edit {
  align-items: center;
  padding: 10px 15px 10px 0px;
}

.upcomingTourPlans__location {
  display: -webkit-inline-box;
  padding: 10px 0px 10px 15px;
  align-items: center;
}

.upcomingTourPlans__locationBox {
  display: -webkit-inline-box;
  align-items: center;
}

.upcomingTourPlans__dateBox {
  display: flex;
}
.upcomingTourPlans__tourDate {
  display: -webkit-inline-box;
  padding: 10px 15px;
  align-items: center;
}

.upcomingTourPlans__tourDay {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12.71625px;
  color: #acacac !important;
  margin-bottom: 0 !important;
  width: 60px;
}

.upcomingTourPlans__tourDate p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 9.71625px;
  margin-bottom: 0;
  padding: 5px 5px !important;
}

.upcomingTourPlans__location p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 9.71625px;
  margin-bottom: 0;
  padding: 5px 5px;
  overflow: hidden;
  min-width: 60px;
  max-width: 100px;
}

@media (max-width: 410px) {
.upcomingTourPlans__location p {
    max-width: 70px;
  }
}

.noTourPlans__bottom_button {
  background: rgb(245, 245, 245);
  border: none;
  padding-right: 0;
}
.noTourPlans__bottom_section {
  display: flex;
}
.noTourPlans__seeAll_icon {
  color: #2b2b2b;
  height: 14px;
  stroke-width: 0.5;
  margin-right: 3px;
}
.noTourPlans__seeAll {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #2b2b2b;
  white-space: nowrap;
}
.upcomingTourPlans__editButton button {
  background: white !important;
  border: none;
  color: #2b2b2b;
  padding: 0;
}
.upcomingTourPlans__deleteSection {
  /* width: 12.49px !important; */
  /* background: red; */
  /* border-top-left-radius: 4.16411px;
  border-bottom-left-radius: 4.16411px; */
  /* height: 44.58px;
  padding-right: 0 !important;
  padding-left: 0 !important;
  border-left: 1px solid red;
  background: #faa3a5; */

  align-items: center;
  /* position: relative; */

  /* width: 48px; */

  height: 44;
  display: flex;
  justify-content: end;
  padding-right: 0 !important;
}

.upcomingTourPlans__deleteSection button {
  min-width: 28px !important;
  min-height: 44px;
  border: none;
  background: #ef5464;
  border-top-right-radius: 4.16411px;
  border-bottom-right-radius: 4.16411px;
  padding: 6px;
}
.css-u4tvz2-MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 11.3816px !important;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
}
.upcomingTourPlans__formRadio {
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  justify-content: end;
  gap: 0.7rem;
}
/* .upcomingTourPlans__dateAndSortSection{
  display: grid !important;
  grid-template-columns: auto auto !important;
  align-items: center;
} */
.upcomingTourPlans__allButton {
  border: none;
  background: #f5f5f5;

}
.upcomingTourPlans__Selected {
  border: none;
  background: #f5f5f5;
  color: #3901FF !important;
  text-decoration: underline !important;
}
.upcomingTourPlans__sortForm {
  display: "grid" !important;
  grid-template-columns: "auto auto auto" !important;
  align-items: "center" !important;
  justify-content: "end" !important;
  gap: "0.2rem" !important;
}

/* no upcoming tour plans css*/

.upcomingTourPlans__noTourPlans_icon{
  display:flex;
  justify-content:center;
   margin-top: 2rem;
}
.upcomingTourPlans__noTourPlans_text{
  text-align: center;
  margin-top: 0.5rem;
  font-weight: 500;
  color:grey;
}

.upcomingTourPlans__label2{
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 5px;
}

.upcomingTourPlans__label{
  font-size: 0.8rem;
  font-weight: 500;
  margin-right: 9px;
}

.upcomingTourPlans__downloadBtn{
    background: #2CC26A !important;
    border-radius: 0.3rem !important;
    border-color: #2CC26A !important;
    color: #F9F9F9 !important;
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    white-space: nowrap;
    float: right;
}