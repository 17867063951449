.marketWorking {
  width: 94%;
  margin: 0.625rem;
  height: 8rem;
  top: 35rem;
  background: #ffffff;
  box-shadow: 0.06rem 0.06rem 0.259rem rgba(20, 20, 20, 0.07);
  border-radius: 0.671rem;
}

.marketWorking__header {
  margin-left: 0 !important;
  width: 100%;
  height: 2.125rem;
  left:1.25rem;
  top: 22.688rem;
  background: #3901ff;
  border-radius: 0.671rem 0.671rem 0rem 0rem;
}

.marketWorking__title {
  height: 0.813rem;
  left: 2.122rem;
  top: 23.375re,;
  text-align: left;
  padding-left: 1.25rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.688rem;
  line-height: 1.875rem;
  color: #ffffff;
  padding-top: 2px
}

.marketWorking__label{
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 2rem;
  text-align: end;
  color: #ffffff;
}

.marketWorking__activeButton::before {
  content: "";
  position: absolute;
  margin-top: 0.75rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.625rem;
  background: #73E8A3;
}

.marketWorking__labelData{
  padding-left: 0.75rem;
  padding-right: 0.625rem;
}

.marketWorking__dataBox{
  padding-top: 0.8rem;
}
  
.marketWorking__data{
  text-align-last: center;
  padding-right: 0px !important;

}  

.marketWorking__dataNumber{
  font-weight: 800;
  font-size: 1.227rem;
  color:#3901FF;
  margin-bottom: -0.25rem;
 }

.marketWorking__dataHeading{
  font-weight: 700;
  font-size:0.738rem;
  color:#3901FF;
  text-align: center;
}


.marketWorking__dataBottomHeading{
  font-weight: 500;
  font-size: 0.488rem;
  color: #595959;
  white-space:nowrap;
  padding-left: 5px;
}

@media screen and (max-width: 370px) {
  .marketWorking__dataHeading {
      font-weight: 700;
      font-size: 0.61rem;
      color: #3901FF;
    }
}

