.feedback-form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 0px 20px 20px 20px;
    padding-top: 0px !important;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 25px;
}

.feedback-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group textarea {
    resize: vertical;
}

.success {
    text-align: center;
    color: green;
    font-weight: bold;
    margin-top: 15px;
}

.feedbackForm-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 42px;
}
.header__logo {
    height: 6.5rem;
    width: 11rem;
    margin-left: 0.5rem;
}

.error {
    color: red;
    margin-top: 5px;
}
.feedback-form-container .submitFeedback {
    border-radius: 5px;
    padding: 0 !important;
    width: 100%;
    background-color: #3901FF !important;
    color: white !important;
    font-size: 13px !important;
    height: 2.2rem;
    margin-right: 10px;
}

.submitFeedback:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .feedback-form-container h2 {
        text-align: center;
        margin-bottom: 25px;
    }

    .error {
        color: red;
        margin-top: 5px;
    }
    .feedback-form-container {
        border: none;
        /* padding: 0 10px; */
        padding: 0px 20px !important;
        box-shadow: none;
        background-color: transparent;
    }

    

    .form-group input,
    .form-group textarea {
        border-radius: 5px;
        padding: 12px;
    }

}
