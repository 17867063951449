.tabComponent__box {
  width: 94%;
  height: 15rem;
  top: 5.5rem;
  margin: 0.625rem 0.625rem 1.625rem 0.625rem;
  background: #ffffff;
  box-shadow: 0.056rem 0.056rem 0.241rem rgba(20, 20, 20, 0.07);
  border-radius: 0.75rem;
}

.tabComponent__header {
  width: 100%;
  height: 2.313rem;
  left: 1.313rem;
  top: 5.5rem;
  background: #3901ff;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
}

.tabComponent__calender {
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 0.75rem;
  margin-left: 0.75rem;
  padding: 0.7rem;
  color: #000000;
  opacity: 0.2;
}

.tabComponent__calenderSvgColor {
  left: 12.5%;
  right: 12.5%;
  top: 41.67%;
  bottom: 58.33%;
  margin-right: 0.3rem;
}

.tabComponent__detailsRows {
  width: 100%;
  height: 2.813rem;
  left: 1.313rem;
  top: 10.188rem;
  background: #fbfbfb;
  margin-bottom: 0.625rem;
}

.tabComponent__info {
  /* padding: 0.938rem 0rem 0.625rem 1.25rem !important; */
  padding: 0.5rem 0rem 0.625rem 1rem !important;
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.61rem;
  line-height: 0.75rem;
  color: #2b2b2b;
  text-align: start;
  height: 2rem;
}

.tabComponent__infoName {
  padding: 0px 0px 0px 15px;
}

@media (min-device-width: 768px) {
  .tabComponent__info {
    font-size: 0.8rem;
  }
}

.tabComponent__info2 {
  padding: 0rem 0rem 0.625rem 1.25rem !important;
  display: inline-block;
  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.62rem;
  line-height: 0.75rem;
  color: #2b2b2b;
  text-align: start;
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 8px;
  color: #2b2b2b;
  opacity: 0.3; */
}

.tabComponent__info2 span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 8px;
  color: #2b2b2b;
  opacity: 0.3;
}

@media (min-device-width: 768px) {
  .tabComponent_info2 span {
    font-size: 0.6rem;
  }
}

.tabComponent__infoValue {
  padding: 0.625rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.188rem;
  text-align: center;
  padding-right: 1.25rem;
  color: #3901ff;
}

@media (max-device-width: 320px) {
  .tabComponent__infoValue {
    font-size: 0.9rem;
  }
}

.tabComponent__contentBox {
  height: 9.375rem;
  max-height: 9.375rem;
  overflow-y: auto;
  overflow-x: hidden;
  /* margin-right: -10px; */
}

.tabComponent__contentBox::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
