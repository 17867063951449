.profile_OuterBox {
  padding: 0.938rem;
}

.profile_InnerBox {
  background: #ffffff;
  box-shadow: 1.24923px 1.24923px 5.35782px rgba(20, 20, 20, 0.07);
  border-radius: 1.25rem;
  padding: 1.25rem;
  height: 9.375rem;
}

.profile_image {
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  top: -7.813rem;
  /* right: -0.938rem; */
  border: 4px solid #3369ff;
}

.profile_dropdown {
  padding: 1px !important;
  position: relative !important;
  top: -11.438rem;
  left: 4.563rem;
  background-color: #ffffffff !important;
  border-radius: 0.938rem !important;
  height: 1.9rem;
  width: 1.9rem;
  border: 1px solid #3369ff;
  white-space: initial !important;
  color: #3369ff !important;
}

.profile_dropdown:hover {
  border-color: #000000;
}

.profile_errorActive {
  color: #ff0000;
  margin-bottom: 0.625rem !important;
  font-size: 0.75rem;
}

.profile_error {
  margin-bottom: 0.625rem !important;
  color: #ffbf00;
  font-size: 0.938rem;
}

.profile_dropdown::after {
  display: none !important;
}

.profile__dropdownMenu p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.489rem;
  line-height: 0.563rem;
  padding: 0.313rem 0.313rem;
  margin: 0rem;
  color: #7b7b7b;
}

.profile__dropdownMenu {
  --bs-dropdown-min-width: 0rem !important;
  --bs-dropdown-link-active-bg: #2cc26a !important;
  background: #f9f9f9 !important;
  box-shadow: 0.078rem 0.078rem 0.334rem rgba(20, 20, 20, 0.07) !important;
  border-radius: 0.433rem !important;
  padding: 0.625rem !important;
  color: #2cc26a !important;
}

.profile__dropdownItems {
  padding: 0rem !important;
  font-size: 0.688rem !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
}

.profile_removeIcon {
  color: #ff0000;
  height: 1.3em;
  width: 1.3em;
}

.profile_editIcon {
  color: #3901ff;
  height: 1.3em;
  width: 1.3em;
}

.profile__dropdownMenu hr {
  margin: 0.5rem 0;
  color: #000000;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

.profile_saveButton {
  width: 50%;
  height: 1.875rem;
  left: 6.313rem;
  top: 23.25rem;
  margin-bottom: 0.625rem;
  color: #ffffff;
  background: #3901ff;
  border-radius: 0.313rem;
}

.profile__detailsBox {
  background: #ffffff;
  box-shadow: 1.24923px 1.24923px 5.35782px rgba(20, 20, 20, 0.07);
  border-radius: 0.948rem;
  padding: 0.313rem;
  margin-top: 0.625rem !important;
}

.profile__icon {
  color: #000000;
  margin-right: 0.313rem;
  height: 0.9rem;
  width: 0.9rem;
}

.profile_label {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 0.811rem;
  line-height: 0.938rem;
  color: #acacac;
  text-align: start;
  padding: 0.625rem;
}

.profile_name {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 0.938rem;
  line-height: 0.938rem;
  color: #000000;
  text-align: center;
  padding: 0.625rem;
  top: -6rem;
  top: 13.5rem;
  position: relative;
  height: 5rem;
}

.profile_entry {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 0.857rem;
  line-height: 0.688rem;
  text-align: start;
  color: #000000;
  word-break: break-all;
  padding: 0.625rem;
}
.profile__menuIcon {
  margin-bottom: 5px;
}

@media only screen and (min-width: 1200px) {
  .profile_InnerBox {
    margin-top: 10% !important;
  }
  .profile_name {
    /* font-size: 25.970.938rem !important; */
    top: 18.375rem !important;
  }
}
@media only screen and (min-width: 1400px) {
  .profile_InnerBox {
    margin-top: 10% !important;
  }
  .profile_name {
    /* font-size: 25.970.938rem !important; */
    top: 19.375rem !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .profile_InnerBox {
    margin-top: 10% !important;
  }
  .profile_name {
    /* font-size: 25.970.938rem !important; */
    top: 17.375rem !important;
  }
}

@media (min-device-width: 770px) and (max-width: 1024px) {
  .profile_InnerBox {
    margin-top: 10% !important;
  }
  .profile_name {
    /* font-size: 25.970.938rem !important; */
    top: 16.188rem !important;
  }
}

@media (max-width: 430px) {
  .profile_InnerBox {
    margin-top: 10% !important;
  }
}

/* @media (min-width: 430px) {
  .profile_model {
    padding: 0.938rem 3.125rem;
  }
} */
@media (max-device-width: 770px) and (min-device-width: 430px) {
  /* .profile_model {
    padding: 0.938rem 4.063rem;
  } */
  .profile_name {
    top: 11.75rem !important;
  }
}

@media (max-device-width: 430px) and (min-device-width: 377px) {
  /* .profile_model {
    padding: 0.938rem 4.063rem;
  } */
  .profile_name {
    top: 13.75rem !important;
  }
}

/* @media (max-device-width: 375px) and (min-device-width: 322px) {
  .profile_model {
    padding: 0.938rem 2.5rem;
  }
} */

@media (max-device-width: 320px) {
  /* .profile_model {
    padding: 0.938rem 0.75rem;
  } */
  .profile_name {
    top: 13.25rem !important;
  }
}

.profile_model {
  display: flex;
  justify-content: center;
  padding: 15px 0px;
}
