.header__logo {
    height: 6.5rem;
    width: 11rem;
    margin-left: 0.5rem;
}

.header__profileImage {
    position: relative;
    /* right: 2rem; */
    top: 1.3rem;
    height: 4rem;
    border-radius: 50%;
    /* border: 2px solid blue; */
    background-color: blue;
}

.profileImageBox{
    text-align: center;
}