/* Outer container for components */
.queryUpdate {
  padding: 0.625rem 1rem !important;
}

.queryUpdate__heading {
  padding: 0.625rem 0rem;
  color: #3901ff;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
}

.queryUpdate__mainbox {
  width: 100%;
  height: 11.063rem;
  background: #ffffff;
  box-shadow: 0.081rem 0.081rem 0.348rem rgba(20, 20, 20, 0.07);
  border-radius: 0.525rem;
  padding: 0.625rem;
  overflow: scroll;
}

/* Inner box*/

.queryUpdate__innerbox {
  display: flex;
}

.queryUpdate__arrow {
  padding: 0.313rem 0.938rem 0px 0px;
}

.queryUpdate__updateArea {
  width: 80%;
  border-left: 2px solid #808080;
  padding: 0 1.25rem;
}

.queryUpdate__infobox {
  position: relative;
  padding: 1.25rem 0px;
}

.queryUpdate__icon {
  width: 1.25rem;
  display: block;
  height: 1.25rem;
  border-radius: 50%;
  color: #ffffff;
  background-color: #2cc26a;
  border: 1px solid #ffffff;
  position: absolute;
  padding: 4px;
  top: 0.625rem;
  left: -1.938rem;
}

/*Inner Box Details Section */

.queryUpdate__dateTime {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 0.688rem;
  line-height: 0.813rem;
  color: #2b2b2b;
  margin-bottom: 0.625rem;
}

.queryUpdate__dueDate {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 0.563rem;
  color: #535353;
  margin-bottom: 0.625rem;
}

.queryUpdate__name {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: #3901ff;
  margin-bottom: 0.625rem;
}
