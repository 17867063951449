.pbiDashboard {
  padding: 0.625rem 1rem !important;
}

.pbiDashboard__headerBox {
  display: flex;
  justify-content: space-between;
  align-self: center;
}

.pbiDashboard__heading {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: auto 5rem;
  padding-left: 0 !important;
}

.pbiDashboard__headingTitle {
  padding: 0.625rem 0rem;
  color: #3901ff;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
}

.pbiDashboard__dropdownBox {
  align-self: center;
}

.pbiDashboard__name {
  color: #3901ff;
  font-weight: 600;
}
.pbiDashboard__drop {
  background: transparent !important;
  color: blue !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  border: none !important;
}

.pbiDashboard__box {
  width: auto;
  /* height: 15rem; */
  height: 100%;
  margin-bottom: 0.5rem;
}

/* .pbiDashboard__box .report-style-class{
    margin-right: -25px;
}

.myTeam__innerBox .report-style-class{
    margin-right: -25px;
} */

.pbiDashboard__drop::after {
  content: none !important;
}
.pbiDashboard__dropdownMenu {
  width: 5rem !important;
  box-shadow: 0.9px 0.9px 3.86px rgb(20 20 20 / 7%);
  border: 0 !important;
  width: 50%;
  transform: translate3d(-15px, 32px, 0px) !important;
}
.pbiDashboard__dropdownItem {
  font-size: 10.5px;
  padding: 2px 6px !important;
  display: flex !important;
  width: 130% !important;
}

.pbiDashboard__dropdownItem  .dropdown-item:focus, .dropdown-item:hover {
    background-color: white !important;
}
.pbiDashboard__dropdownItem .form-check {
  min-height: 1.3rem;
  display: block;
  min-height: 0rem;
  padding-left: 0em;
  margin-bottom: 0rem;
}

/* .pbiDashboard__dropdownItem__formCheckInput_checked {
    background-color: #2CC26A !important;
    border: 0.456682px solid #C7C8CA !important;
} */
.pbiDashboard__selectedValue {
  font-size: 12px;
}

.pbiDashboard_inputRadioButton input {
  width: 15px !important;
  height: 15px !important;
}

.pbiDashboard_inputRadioButton input:checked {
  background-color: #2cc26a !important;
  border-color: #2cc26a !important;
}

.pbiDashboard__dropdownItem label {
  margin-left: 5px;
  align-self: center;
  padding: 5px 0px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11.83px;
  line-height: 10px;
  display: block;
}

.pbiDashboard__inputradioBox{
    padding: 0px 20px 0 10px;
    /* margin-left: 12px; */
}


@media screen and (min-width: 770px) {
    .pbiDashboard_inputRadioButton label {
        margin-left: 12px;
        align-self: center;
        padding: 4px 0px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14.83px;
        line-height: 20px;
        display: block;
    }

    .pbiDashboard__dropdownMenu {
        width: 6rem !important;
      }
      
      .pbiDashboard__inputradioBox{
          padding: 0px 20px 0 10px;
          margin-left: 12px;
      }

      .pbiDashboard_inputRadioButton input {
        width: 20px !important;
        height: 20px !important;
      }
      
      .pbiDashboard__inputradioBox{
          padding: 0px 20px 0 10px;
          margin-left: 12px;
      }
  }

  .visitDetails__mainBox {
    width: 100%;
    /* height: 5.5rem; */
    background: #ffffff;
    box-shadow: 0.081rem 0.081rem 0.348rem rgba(20, 20, 20, 0.07);
    border-radius: 0.525rem;
    padding: 15px;
    /* margin: 0.625rem 0rem; */
  }

  .pbi__outerBox{
    padding: 0.625rem 1rem !important;
  }