.totalFeedback{
  padding: 0.625rem 1rem;
}
.totalFeedback__box {
  background: #ffffff;
  box-shadow: 1.24606px 1.24606px 5.3442px rgba(20, 20, 20, 0.07);
  border-radius: 2.76902px;
  padding-top: 0.5rem;
  width: 100%;
}
.totalFeedback__heading {
  color: #3901ff;
  font-weight: 600;
  margin: 0px;
  padding: 10px 0px 10px 0px;
}

.totalFeedback__count {
  display: flex;
  justify-content: end;
  font-weight: 500;
  font-size: 13.4606px !important;
  color: #f27042;
}

.totalFeedback__toDay {
  display: flex;
  justify-content: start;
  color: #808080;
  font-weight: 500;
  font-size: 13.4606px !important;
}
.totalFeedback__date {
  display: flex;
  gap: 0.5rem;
  font-weight: 500;
  font-size: 9.92254px;
  color: #808080;
  font-family: "Maven Pro";
  padding-right: 0px !important;
  gap: 0.7rem;
}
.totalFeedback__location {
  display: flex;
  gap: 0.5rem;
  font-weight: 500;
  font-size: 9.92254px;
  color: #808080;
  font-family: "Maven Pro";
  padding-left: 0px !important;
  margin-left: 13px;
  gap: 0.7rem;
}

.totalFeedback__dataOuterDiv{
  max-height: 190px;
  overflow: auto;
}
.totalFeedback__FeedbackText {
  border-left: 3px solid #969696;
  background: rgba(150, 150, 150, 0.13);
  margin-bottom: 0.5rem !important;
  align-items: center !important;
  min-height: auto;
  max-height: 2rem;
  white-space: nowrap;
}

.totalFeedback__FeedbackText p {
  font-weight: 500;
  /* font-size: 8.92254px; */
  font-size: 0.5rem;
  color: #2b2b2b;
  font-family: "Maven Pro";
  height: auto;
}
.totalFeedback__seeAll {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 8.92254px;
  color: #2cc26a;
  white-space: nowrap;
  margin-bottom: 0px !important;
}

.totalFeedback__bottom_section {
  display: flex;
}
.totalFeedback__seeAll_icon {
  color: hsla(145, 63%, 47%, 1);
  height: 13px;
  width: 10px;
  margin-left: 0.3rem;
  stroke-width: 1;
}

.totalFeedback__bottom_button {
  background: white;
  border: none;
  padding-left: 0;
  display: flex;
  text-align: center;
}
.totalFeedback__date p{
  /* margin-bottom: 0 !important;
  margin-top: -2px !important;
  padding-bottom: 0.5rem; */
  padding: 1px 0px;
}

.totalFeedback__location p{
  margin-bottom: 0.5rem !important;
  margin-top: -2px !important;
}

.totalFeedback__FeedbackText_p{
  display: flex;
}

.totalFeedback__todayEditBtn{
  font-size: 0.9rem;
}

.totalFeedback__todaySubmitBtn{
  background: #2cc26a !important;
  border-radius: 0.3rem !important;
  border-color: #2cc26a !important;
  color: #f9f9f9 !important;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.606rem !important;
  line-height: 0.8rem;
  white-space: nowrap;
}

.totalFeedback__edit {
  align-items: center;
  padding: 0px 10px 0px 5px;
}

.totalFeedback__todayInnerBoxText{
  width: 100%;;
  align-items: center !important;
  justify-content: space-between;
  padding: 0.2rem 0.625rem 0.2rem 0.625rem !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.6rem;
  color: #2b2b2b;
  resize: none;
  height: 25px;
  border-color: #F5F5F5F5;
}

.totalFeedback__todayInnerBoxEditText{
  width: 100%;;
  align-items: center !important;
  justify-content: space-between;
  padding: 0.2rem 0.625rem 0.2rem 0.625rem !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.6rem;
  color: #2b2b2b;
  resize: none;
  height: 25px;
  border-color: #2cc26a ;
}