.query{
    padding: 0.625rem 1rem !important;
}

.query__name{
    color:#3901FF;
    font-weight: 600;
    padding: 0.625rem 0rem;
    margin: 0px;
}

/* .query__box{
    height: 350px;
    overflow-y: auto;
    margin-right: -10px;
} */

.query__box{
    width: 100%;

}

.query__innerBox{
    margin-bottom: 15px;
    border-radius: 12px;
    background-color: white;
    overflow: hidden;
    box-shadow: 0.081rem 0.081rem 0.348rem rgb(20 20 20 / 7%);
}

.query a{
    text-decoration: none;
}

.query__header{
    border-radius: 12px 12px 0px 0px;
    height: 2.7rem;
    background: #3901FF;
    display: grid;
    grid-template-columns: 18% 68% 13%;
}
.query__profile{
    height: 25px;
    width: 25px;
    color: #fff;
    margin-top: 10px;
    margin-left: 12px;
}
.query__profileName{
    font-size: 10px;
    color: #fff;
    font-weight: 700;
    margin-top: 10px;
}
.query__profileSubName{
    font-size: 8px;
    color: #fff;
}

.query__profileOptions{
    color: white;
    margin-top: 10px;
    height: 15px;
    width: 20px;
    
}
.query__subjectSection{
    color: #F27042;
    border: none !important;
}
.query__orderDetailsTable{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 0.625rem;
    /* box-shadow: 0.1px 1.27926px 0.27926px 2.48661px rgb(35 35 35 / 3%); */
    border-radius: 8px;
    overflow: hidden;
    border: 0.692008px solid #EEEEEE;
}
.query__details{
    font-size: 11px;
}

.query__orderDetailsProfileSection{
    text-align: center;
    background: #EEEEEE !important;
    border-radius: 5px 0 0 5px;
}
.query__orderDetailsData{
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 2px !important;
}
.query__orderDetailsTitle{
    font-size: 8px;
    font-weight: 400;
    margin-bottom: 0 !important;
}
.query__orderDetailsProfileName{
    font-size: 11px;
    font-weight: 600;
    color: #808080;
    margin-bottom: 1px !important;
}

.query__orderDetailsProfileRelated{
    font-size: 7px;
    font-weight: 400;
    color: #808080;
}
.query__orderDetailsName{
    font-size: 10px;
    font-weight: 700;
    margin-left: 10px;
}
.query__datetime{
    padding: 3px;
    background : white;
    /* margin-left: 1rem; */  
}

.query__statusRelated{
    background : white;
    border-radius: 0 5px 5px 0;
    /* margin-left: 1rem;
    margin-top: 5px; */
}

.query__shareTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size:  0.606rem;
    line-height: 0.688rem;
    color: #2cc26a;   
    margin-top: 0.5rem !important;
  }
  
  .query__shareIcon {
    color: #2cc26a;
    height: 0.75rem;
    width: 0.75rem;
    background-color: #ffffff;
    margin-right: 0.313rem;
  }

  .query__submitBtn {
    background: #2cc26a !important;
    border-radius: 0.173rem !important ;
    border-color: #2cc26a !important;
    color: #f9f9f9 !important;
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size:  0.606rem !important;
    line-height: 0.688rem;
    white-space: nowrap;
    width: 100%;
    height: 20px;
    padding: 0px !important;
  }

  .query__viewDetailsBox{
    display: flex;
    justify-content: space-around;
  }

  .query__viewDetailsBtn {
    background: #2cc26a !important;
    border-radius: 0.173rem !important;
    border-color: #2cc26a !important;
    color: #f9f9f9 !important;
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 0.606rem !important;
    line-height: 0.8rem;
    white-space: nowrap;
    width: 25%;
    padding: 2px !important;
    margin-bottom: 10px;
  }

  /* .query__box tbody, td, tfoot, th, thead, tr{
    border-width: 0 !important;
  } */

  .query__box  td{
    border-width: 0 !important;
  }

.query__header .dropdown-toggle::after{
    content: none !important;
}
.query__header .dropdown-menu{
    --bs-dropdown-min-width:3.5rem !important;
    transform :translate3d(-9.5px, 45px, 0px) !important;
    border: 0;
    box-shadow: 0.9px 0.9px 3.86px rgb(20 20 20 / 7%);
}
.query__header .dropdown-item{
    font-size: 8px;
    padding: 1px 2px 1px 8px !important;
}
.query__header .btn-primary{
    background: none !important;
    border: none !important;
}
.query__FollowUp .dropdown-toggle::after{
    content: none !important;
}
.query__FollowUp .dropdown-menu{
    --bs-dropdown-min-width:4.5rem !important;
    transform: translate3d(3.5px, -24px, 0px) !important;
    border: 0;
    box-shadow: 0.9px 0.9px 3.86px rgb(20 20 20 / 7%);
}
.query__FollowUp .dropdown-item{
    font-size: 8px;
    font-weight: 500;
    padding: 1px 2px 3px 8px !important;
}
.query__followIcons{
    margin-right: 3px;
}

.searchTour__searchTourBox {
    display: grid;
    grid-template-columns: 1.3rem auto;
    height: 1.8rem;
    border: 0.694018px solid #BCBCBC;
    border-radius: 0.6rem;
}

.form-control {
    margin: 1px 0;
    border: none !important;
    height: 27px;
    padding: 0.375rem 0.75rem !important;
    font-size: 11.6px !important;
    background-color: transparent !important;
}

.form-control:focus {
    box-shadow: none !important;
}

.searchTour__searchIcon {
    position: relative;
    width: 20px;
    height: 23px;
    left: 5px;
    top: 3px;
    color: #BCBCBC;

}

.searchTour__searchTourBox button {
    width: 50px !important;
    height: 18px !important;
    border-radius: 0.4rem;
    background-color: #3901FF !important;
    color: #ffff !important;
    padding: 0px;
    font-size: 9px;
    border: none;


}

.searchTour__searchBox {
    display: grid;
    grid-template-columns: auto 25%;
    padding-bottom: 1rem;
    grid-gap: 1rem;
}

.searchTour__newTourBtn {
    background-color: #FFFFFF !important;
    height: 1.8rem;
    width: 5rem;
    border-radius: 0.5rem !important;
    color: #3901FF !important;
    text-transform: capitalize !important;
    font-size: 10px !important;
    white-space: nowrap;
    font-weight: bold;
    padding: 7px !important;
    box-shadow: 1.24923px 1.24923px 5.35782px rgba(20, 20, 20, 0.07) !important;
}

.searchTour__newTourIcon {
    color: #3901FF;
    font-size: 15px;
    margin-right: 0.5rem;
}

.searchTour__searchTourBox .dropdown-toggle::after {
    display: none !important;
}

.searchTour__searchTourBox .dropdown-menu.show{
    padding: 0px;
    min-width:0.5rem;
}

.searchTour__searchTourBox .dropdown-item{
    font-size: 0.6rem;
    font-weight: 400 !important;
}

.searchTour__newTourBtnNew {
    background-color: #FFFFFF !important;
    height: 1.8rem;
    width: 5rem;
    border-radius: 0.5rem !important;
    color: #3901FF !important;
    text-transform: capitalize !important;
    font-size: 10px !important;
    white-space: nowrap;
    font-weight: bold;
    padding: 7px !important;
    box-shadow: 1.24923px 1.24923px 5.35782px rgba(20, 20, 20, 0.07) !important;
    position: relative;
    left: 108%;
    bottom: 109%;
}