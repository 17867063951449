
/* Outer container for components */
.currentDayTour {
  padding: 0px 1rem !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.currentDayTour__mainBox {
  width: 100%;
  /* height: 11.059rem; */
  background: #ffffff;
  box-shadow: 1.28262px 1.28262px 5.50103px rgba(20, 20, 20, 0.07);
  border-radius: 0.519rem;
  margin: 0.625rem 0px;
}

/* Left sideCard */
.currentDayTour__sidecard {
  background: #c6c6c6;
  opacity: 0.8;
  padding: 0.938rem 0.938rem 0.625rem 0.938rem !important;
  border-top-left-radius: 0.519rem;
  border-bottom-left-radius: 0.519rem;
}

@media (min-width:325px){
  .currentDayTour__sidecard {
    padding: 1.563rem 0.938rem 0.625rem 0.938rem !important;
  }
}


.currentDayTour__sidecardImgTop {
  width: 3rem;
  height: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.currentDayTour__sidecardTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 0.564rem;
  line-height: 0.75rem;
  text-align: center;
  color: #808080;
  margin-top: 1rem;
  margin-bottom: 0.3rem;
}

.currentDayTour__sidecardText {
  font-style: normal;
  font-weight: 600;
  font-size: 0.564rem;
  line-height: 0.75rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #808080;
}

@media screen and (min-width: 768px) {
  .currentDayTour__sidecardText {
    margin-bottom: 0.5rem;
  }
}

.currentDayTour__sidecardButton {
  box-sizing: border-box;
  background: #3901ff;
  border: 0.026rem solid #3901ff;
  border-radius: 1.104rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.622rem; 
  color: #ffffff; 
  padding: 2px 11px;
  height: 100%;
}

/* Right sideBox */

.currentDayTour__rightBox {
  padding: 0.625rem !important;
}

.currentDayTour__title {
  font-style: normal;
  font-weight: 600;
  font-size: 0.83rem;
  line-height: 1rem;
  color: #f27042;
  margin-bottom: 0.3rem !important;
}

.currentDayTour__desp {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.491rem;
  line-height: 0.563rem;
  color: #2b2b2b;
  margin: 0rem 0rem 0.5rem 0rem;
}

.currentDayTour__despRow {
  display: flex;
  column-gap: 5px;
}

.currentDayTour__despBox {
  display: flex;
  height: 1.763rem;
  background: #f5f5f5;
  border-radius: 0.16rem;
  margin-bottom: 0.313rem;
  padding: 0px !important;
  width: -webkit-fill-available;
}

.currentDayTour__despBoxName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.481rem;
  line-height: 0.563rem;
  color: #4b4b4b;
  padding: 0.313rem;
  width: inherit;
}

.currentDayTour__despBoxValue {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 0.481rem;
  line-height: 0.563rem;
  color: #2b2b2b;
  padding: 0.625rem;
  text-align: center;
}

.currentDayTour__buttonBox {
  background: #f98d67;
  border-radius: 0.304rem;
  margin: 0rem 0rem 0rem 0rem !important;
  padding: 0.313rem !important;
  border: 1px solid #f98d67;
}

.currentDayTour__buttonName {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.634rem;
  line-height: 0.625rem;
  text-align: end;
  color: #ffffff;
  padding: 0.313rem;
  text-align: center;
  
}

.currentDayTour__buttonValue {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.534rem;
  line-height: 0.625rem;
  text-align: start;
  color: #ffffff;
  padding: 0.313rem;
}

.no-gutter.row,
.no-gutter.container,
.no-gutter.container-fluid{
  margin-left: 0;
  margin-right: 0;
}


