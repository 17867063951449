/* Outer container for components */
.myDealerStatus {
  padding: 0.625rem 1rem !important;
}

.myDealerStatus__heading{
  padding: 0.625rem 0rem;
  color:#3901FF;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
}

.myDealerStatus__mainBox {
  width: 100%;
  /* height: 5.5rem; */
  background: #ffffff;
  box-shadow: 0.081rem 0.081rem 0.348rem rgba(20, 20, 20, 0.07);
  border-radius: 0.525rem;
  /* margin: 0.625rem 0rem; */
}

/* Left sideCard */
.myDealerStatus__sidecard {
  background: #c6c6c6;
  opacity: 0.8;
  height: 5.5rem;
  padding: 0rem !important;
  padding: 0.625rem 0.7rem !important;
  border-top-left-radius: 0.519rem;
  border-bottom-left-radius: 0.519rem;
}

.myDealerStatus__sidecardImgTop {
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.myDealerStatus__sidecardTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 0.564rem;
  line-height: 0.75rem;
  text-align: center;
  color: #808080;
  margin-top: 0.313rem;
  margin-bottom: 0.3rem;
}

.myDealerStatus__sidecardText {
  font-style: normal;
  font-weight: 600;
  font-size: 0.564rem;
  line-height: 0.75rem;
  /* margin-bottom: 1rem; */
  text-align: center;
  color: #808080;
  margin: 0rem;
}

/* Right sideBox */

.myDealerStatus__rightBox {
  padding: 0.625rem !important;
}

.titleBox {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.313rem;
}

.myDealerStatus__titleInnerBox {
  display: flex;
  justify-content: end;
}

.myDealerStatus_title {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.313rem 0.313rem;
  color: #808080;
}

.myDealerStatus__titleBoxValue {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 0.84rem;
  line-height: 1rem;
  color: #f27042;
  padding: 0.313rem 0.938rem 0.313rem 0rem;
}

.myDealerStatus__menuIcon {
  color: black;
  height: 0.75rem;
  width: 0.75rem;
  background-color: #ffffff;
}

.myDealerStatus__dropDownButton {
  background-color: #f5f5f5 !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 0.618rem !important;
  font-family: "Roboto" !important;
  color: #3901ff !important;
  line-height: 0.625rem !important;
  padding: 0rem !important;
}

.myDealerStatus__dropDownButton::after {
  display: none !important;
}

.myDealerStatus__dropdownMenu p{
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.489rem;
  line-height: 0.563rem;
  padding: 0.313rem 0.313rem;
  margin: 0rem;
  color: #7b7b7b;
}

.myDealerStatus__dropdownMenu {
  --bs-dropdown-min-width: 0rem !important;
  --bs-dropdown-link-active-bg: #2CC26A !important;
  background: #f9f9f9 !important;
  box-shadow: 0.078rem 0.078rem 0.334rem rgba(20, 20, 20, 0.07) !important;
  border-radius: 0.433rem !important;
  padding: 0.625rem !important;
  color: #2CC26A !important;
}

.myDealerStatus__dropdownItems{
  padding: 0rem !important;
  display: flex !important;
}

.myDealerStatus__dropdownItems input{
  accent-color: green !important;
}

.myDealerStatus__rightBox p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.489rem;
  line-height: 0.563rem;
  margin: 0rem;
  color: #2b2b2b;
  padding: 0.313rem 0.313rem;
}

.myDealerStatus__despBox {
  display: flex;
  justify-content: space-between;
}

.myDealerStatus__despBoxValue {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.552rem;
  line-height: 0.9rem;
  color: #f27042;
  padding: 2px 0.313rem;
  /* text-align: center; */
  width: 4.688rem;
  word-break: break-all;
  /* align-self: center; */
}

.myDealerStatus__headerBox {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.313rem;
}

.myDealerStatus__headerValue {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 7.94065px;
  line-height: 9px;
  color: #2B2B2B;
  padding: 2px 0.313rem;
  /* text-align: center; */
  width: 4.688rem;
}

@media (min-width: 321px) {

  .myDealerStatus__headerValue {
    font-size: 0.684rem;
    width: 7.25rem;
    margin-bottom: 5px;
  }

  .myDealerStatus__despBoxValue {
    font-size: 0.6rem;
    width: 7.25rem;
    /* margin-bottom: 5px; */
    word-break: break-all;
    /* align-self: center; */
  }

}

@media (min-width: 426px) {

  .myDealerStatus__headerValue {
    font-size: 0.746rem;
    width: 8.25rem;
    margin-bottom: 5px;
  }

  .myDealerStatus__despBoxValue {
    font-size: 11.83133px;
    width: 8.25rem;
    margin-bottom: 5px;
    word-break: break-all;
    /* align-self: center; */
  }

}

@media (min-width: 768px) {

  .myDealerStatus__headerValue {
    font-size: 0.746rem;
    width: 8.25rem;
    margin-bottom: 5px;
    white-space: nowrap;
  }

  .myDealerStatus__despBoxValue {
    font-size: 11.83133px;
    width: 8.25rem;
    margin-bottom: 5px;
    word-break: break-all;
    white-space: nowrap;
    /* align-self: center; */
  }

}

.myDealerStatus__plansBox{
  min-height: 0rem;
  max-height: 7rem;
  overflow: auto;
}
