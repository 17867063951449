.queryDetails{
    padding: 0.625rem 1rem !important;
}

.queryDetails__name{
    color:#3901FF;
    font-weight: 600;
    padding: 0.625rem 0rem;
    margin: 0px;
}

.queryDetails__box{
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0.081rem 0.081rem 0.348rem rgb(20 20 20 / 0%);
    background: #fff;
}
.queryDetails__header{
    border-radius: 12px 12px 0px 0px;
    height: 3.7rem;
    background: #3901FF;
    display: grid;
    grid-template-columns: 18% 68% 13%;
}
.queryDetails__profile{
    height: 35px;
    width: 35px;
    color: #fff;
    margin-top: 10px;
    margin-left: 12px;
}
.queryDetails__profileName{
    font-size: 13px;
    color: #fff;
    font-weight: 700;
    margin-top: 10px;
}
.queryDetails__profileSubName{
    font-size: 9px;
    color: #fff;
}

.queryDetails__profileOptions{
    color: white;
    margin-top: 10px;
    height: 30px;
    width: 20px;
    
}
.queryDetails__subjectSection{
    color: #F27042;
    border: none !important;
}
.queryDetails__orderDetailsTable{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 0.625rem;
    border-radius: 8px;
    overflow: hidden;
    border: 0.692008px solid #EEEEEE;
}
.queryDetails__details{
    font-size: 11px;
}

.queryDetails__orderDetailsProfileSection{
    display: flex;
    background: #eee!important;
    border-radius: 5px 0 0 5px;
    padding-top: 0.5rem;
    text-align: center;
    width: 90%;
    flex-direction: column;
    /* justify-content: center; */
    /* text-align: center; */
    align-items: center;
}
.queryDetails__orderDetailsData{
    font-size: 10px;
    font-weight: 500;
    margin-bottom: 2px !important;
}
.queryDetails__orderDetailsTitle{
    font-size: 8px;
    font-weight: 400;
    margin-bottom: 0 !important;
}
.queryDetails__orderDetailsProfileName{
    font-size: 11px;
    font-weight: 600;
    color: #808080;
    margin-bottom: 1px !important;
}

.queryDetails__orderDetailsProfileRelated{
    color: #000000;
    font-size: 9px;
    font-weight: 400;
    word-wrap: break-word;
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    width: 66%;
    font-weight: bolder;
    border: none;
    line-height: 11px;
    background:#eee ;
}
.queryDetails__orderDetailsName{
    font-size: 10px;
    font-weight: 700;
    margin-left: 10px;
}
.queryDetails__datetime{
    padding: 3px;
    background : white;
    /* margin-left: 1rem; */
    
}
.queryDetails__statusRelated{
    background : white;
    border-radius: 0 5px 5px 0;
    /* margin-left: 1rem;
    margin-top: 5px; */
}
.queryDetails__shareTitle {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size:  0.606rem;
    line-height: 0.688rem;
    color: #2cc26a;   
    margin-top: 0.5rem !important;
  }
  
  .queryDetails__shareIcon {
    color: #2cc26a;
    height: 0.75rem;
    width: 0.75rem;
    background-color: #ffffff;
    margin-right: 0.313rem;
  }

  .queryDetails__submitBtn {
    background: #2cc26a !important;
    border-radius: 0.173rem !important ;
    border-color: #2cc26a !important;
    color: #f9f9f9 !important;
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size:  0.606rem !important;
    line-height: 0.688rem;
    white-space: nowrap;
    width: 100%;
    height: 20px;
    padding: 0px !important;
  }

  /* .queryDetails__box tbody, td, tfoot, th, thead, tr{
    border-width: 0 !important;
  } */

  .queryDetails__box  td{
    border-width: 0 !important;
  }

.queryDetails__header .dropdown-toggle::after{
    content: none !important;
}
.queryDetails__header .dropdown-menu{
    --bs-dropdown-min-width:3.5rem !important;
    transform :translate3d(-9.5px, 45px, 0px) !important;
    border: 0;
    box-shadow: 0.9px 0.9px 3.86px rgb(20 20 20 / 7%);
}
.queryDetails__header .dropdown-item{
    font-size: 8px;
    padding: 1px 2px 1px 8px !important;
}
.queryDetails__header .btn-primary{
    background: none !important;
    border: none !important;
}
.queryDetails__FollowUp .dropdown-toggle::after{
    content: none !important;
}
.queryDetails__FollowUp .dropdown-menu{
    --bs-dropdown-min-width:4.5rem !important;
    transform: translate3d(3.5px, -24px, 0px) !important;
    border: 0;
    box-shadow: 0.9px 0.9px 3.86px rgb(20 20 20 / 7%);
}
.queryDetails__FollowUp .dropdown-item{
    font-size: 8px;
    font-weight: 500;
    padding: 1px 2px 3px 8px !important;
}
.queryDetails__followIcons{
    margin-right: 3px;
}