.myUserDetails__outerBox {
  padding: 0.625rem 1rem !important;
}

/* .myUserDetails__innerBox{
    width: 100%;
    background: #ffffff;
    box-shadow: 0.081rem 0.081rem 0.348rem rgb(20 20 20 / 7%);
    border-radius: 0.525rem;
    overflow-y: auto;
    margin-right: -0.625rem;
} */

/* .myUserDetails__detailsBox{
    padding: 0.625rem 0.625rem 0px 0.625rem;
} */

.myUserDetails__heading {
  padding: 0.625rem 0rem;
  color: #3901ff;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-top: 0.625rem;
}

.myUserDetails__innerBox {
  width: 100%;
}

.myUserDetails__form {
  width: 100%;
}
.myUserDetails__inputBox {
  border: 0.043rem solid #808080;
  border-radius: 0.3rem;
  width: 100%;
  padding-left: 0.75rem;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.731rem;
  line-height: 0.688rem;
  color: #2b2b2b;
  height: 1.7rem;
  margin-bottom: 0.6rem;
  margin-top: 3px;
}

.myUserDetails__sup {
  color: red;
}

.myUserDetails__inputBoxError {
  border: 0.043rem solid #ef5464;
  border-radius: 0.3rem;
  width: 100%;
  padding-left: 0.75rem;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.731rem;
  line-height: 0.688rem;
  color: #2b2b2b;
  height: 1.596rem;
}

.myUserDetails__innerBox p {
  margin: 0px;
  color: #ef5464;
  font-size: 0.731rem;
  padding: 0.313rem 0px;
  white-space: nowrap;
}

.myUserDetails__inputBox:focus-visible {
  outline: none;
}

.myUserDetails__textarea {
  resize: none;
  border: 0.043rem solid #808080;
  border-radius: 0.3rem;
  /* margin-top: 0.75rem; */
  width: 100%;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.731rem;
  line-height: 0.688rem;
  color: #808080;
  padding: 0.75rem;
  /* margin-bottom: 0.375rem; */
}

.myUserDetails__textareaError {
  resize: none;
  border: 0.043rem solid #ef5464;
  border-radius: 0.3rem;
  /* margin-top: 0.75rem; */
  width: 100%;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.668rem;
  line-height: 0.688rem;
  color: #808080;
  padding: 0.75rem;
  /* margin-bottom: 0.375rem; */
}

.myUserDetails__textarea:focus {
  outline: none;
}
.myUserDetails__validation {
  color: red;
}

.myUserDetails__categorySelect {
  background-image: url(../../Assets/Images/downArrow.png) !important;
  border: 0.043rem solid #808080 !important;
  border-radius: 0.3rem;
  color: #808080 !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 0.731rem !important;
  margin-bottom: 0.6rem;
  line-height: 0.8rem !important;
}

.myUserDetails__categorySelectError {
  background-image: url(../../Assets/Images/downArrow.png) !important;
  border: 0.043rem solid #ef5464 !important;
  border-radius: 0.3rem;
  color: #808080 !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 0.731rem !important;
  line-height: 0.8rem !important;
  /* margin-bottom: 0.375rem; */
}

.myUserDetails__categorySelectError:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

.myUserDetails__categorySelect:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

.myUserDetails__defaultOption {
  outline: none !important;
  box-shadow: none !important;
}

/* .myUserDetails__subCategorySelect {
        margin-top: 0.375rem;
      } */

.myUserDetails__categorySelect:focus {
  box-shadow: none !important;
  border: 0.043rem solid #808080 !important;
}

.myUserDetails__questionLabel {
  color: #808080 !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 0.9rem !important;
  margin-bottom: 0.6rem;
  padding-top: 2px;
}

.myUserDetails__sectionHeading {
  padding: 0.625rem 0rem;
  /* color:#3901FF; */
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.25rem;
  text-align: center;
}

.myUserDetails__radioButton {
  justify-content: space-around;
}

.myUserDetails__radioOption {
  display: flex;
  padding-left: 0.625rem;
  gap: 1rem;
}

.myUserDetails__option {
  color: #808080 !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 0.9rem !important;
  margin-bottom: 0.6rem;
  padding-top: 2px;
  margin-right: 0.625rem;
  padding: 0.625rem;
}

.myUserDetails__checkboxButton {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0.625rem;
}

.myUserDetails__displayFileName {
  display: flex;
  align-items: center;
  padding-left: 0.3rem;
}

.myUserDetails__fileIcon {
  font-size: 0.75rem;
  font-weight: 600;
}

.myUserDetails__fileUploadName {
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 0.5rem;
}

.myUserDetails__removeUploadFile {
  background: none !important;
  border: none !important;
  font-size: 0.8rem !important;
  color: #2b2b2b !important;
  opacity: 0.6;
  font-weight: 500 !important;
  display: flex !important;
  justify-content: end;
}

.myUserDetails__inputWrapper {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.668rem;
  line-height: 0.688rem;
  color: #2cc26a;
  margin-top: 0.5rem;
  text-align: end;
}

.myUserDetails__fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  content-visibility: hidden;
}

.myUserDetails__submitBtn {
  background: #2cc26a !important;
  border-radius: 0.3rem !important ;
  border-color: #2cc26a !important;
  color: #f9f9f9 !important;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.606rem !important;
  line-height: 0.688rem;
  white-space: nowrap;
  width: 100%;
}

.myUserDetails__submitBtn:focus-visible {
  outline: none;
}

.myUserDetails__fileUploadTitle {
  font-size: 0.625rem;
  color: #3901FF;
  font-weight: 500;
  margin-left: 0.5rem;
  /* margin-top: 0.5rem; */
}
.myUserDetails__displayFileName {
  background: #F5F5F5;
  border-radius: 0.25rem;
  display: grid;
  grid-template-columns: 63% 37%;
  align-items: center;
  padding-left: 0.8rem;
  /* margin-top: 0.3rem; */
}
.myUserDetails__fileIcon {
  color: #3901FF;
  height: 0.7rem;
  width: 0.7rem;
}
.myUserDetails__fileUploadName {
  font-size: 0.8rem;
  font-weight: 500;
  color: #000000;
  margin-left: 0.5rem;
}
.myUserDetails__removeUploadFile {
  background: none !important;
  border: none !important;
  font-size: 0.8rem !important;
  color: #2B2B2B !important;
  opacity: 0.6;
  font-weight: 500 !important;
  display: flex !important;
  justify-content: end;

}