.mySR__outerBox {
  width: auto;
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
  align-items: center;
}

.mySR__innerBox{
  width: 100%;
}

.mySR__innerBox p{
  margin: 0px;
  color: #EF5464;
  font-size: 0.60rem;
  padding: 0.313rem 0px;
  white-space: nowrap;
}

.mySR__heading {
  padding: 0.625rem 0rem;
  color: #3901FF;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
}

.mySR__inputBox {
  border: 0.043rem solid #808080;
  border-radius: 0.3rem;
  width: 100%;
  margin-bottom: 0.6rem;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 0.731rem;
  line-height: 0.688rem;
  color: #808080;
  padding: 0.75rem;
  height: 1.596rem;
}

.mySR__inputBox:focus-visible{
  outline: none; 
}

.mySR__inputBoxError {
  border: 0.043rem solid #EF5464;
  border-radius: 0.3rem;
  width: 100%;
  /* margin: 0rem 0 0.375rem 0; */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 0.731rem;
  line-height: 0.688rem;
  color: #808080;
  padding: 0.75rem;
  height: 1.596rem;
}

.mySR__textarea {
  resize: none;
  border: 0.043rem solid #808080;
  border-radius: 0.3rem;
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 0.731rem;
  line-height: 0.688rem;
  color: #808080;
  padding: 0.75rem;
  margin-bottom: 0.375rem;
}

.mySR__textarea:focus-visible{
  outline: none; 
}

.mySR__textareaError {
  resize: none;
  border: 0.043rem solid #EF5464;
  border-radius: 0.3rem;
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 0.731rem;
  line-height: 0.688rem;
  color: #808080;
  padding: 0.75rem;
  /* margin-bottom: 0.375rem; */
}

.mySR__textarea:focus {
  outline: none;
}

.mySR__categorySelect {
  background-image: url(../../../Assets/Images/downArrow.png) !important;
  border: 0.043rem solid #808080 !important;
  border-radius: 0.3rem;
  color: #808080 !important;
  font-family: 'Roboto' !important;
  font-weight: 400;
  font-size: 0.731rem !important;
  margin-bottom: 0.6rem;
  line-height: 0.80rem !important;
}

.mySR__categorySelectError {
  background-image: url(../../../Assets/Images/downArrow.png) !important;
  border: 0.043rem solid #EF5464 !important;
  border-radius: 0.3rem;
  color: #808080 !important;
  font-family: 'Roboto' !important;
  font-weight: 400;
  font-size: 0.731rem !important;
  line-height: 0.80rem !important;
  /* margin-bottom: 0.375rem; */
}

.mySR__categorySelectError:focus-visible{
  outline: none !important; 
  box-shadow: none !important;
}

.mySR__categorySelect:focus-visible{
  outline: none !important; 
  box-shadow: none !important;
}

.mySR__defaultOption{
  outline: none !important; 
  box-shadow: none !important;
}

/* .mySR__subCategorySelect {
  margin-top: 0.375rem;
} */

.mySR__categorySelect:focus {
  box-shadow: none !important;
  border: 0.043rem solid #808080 !important;
}

/* .mySR__uploadFile {
  padding-top: 0.438rem;
} */

.mySR__inputWrapper {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 0.668rem;
  line-height: 0.688rem;
  color: #2CC26A;
  margin-top: 0.5rem;
  text-align: end;
}

.mySR__fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  content-visibility: hidden;
}

.mySR__addBtn {
  background: #2CC26A !important;
  border-radius: 0.3rem !important;
  border-color: #2CC26A !important;
  color: #F9F9F9 !important;
  font-family: 'Roboto' !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.606rem !important;
  line-height: 0.688rem;
  white-space: nowrap;
  width: 100%;
}

.mySR__fileUploadTitle {
  font-size: 0.625rem;
  color: #3901FF;
  font-weight: 500;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

.mySR__displayFileName {
  background: #F5F5F5;
  border-radius: 0.25rem;
  display: grid;
  grid-template-columns: 63% 37%;
  align-items: center;
  padding-left: 0.8rem;
  margin-top: 0.3rem;
}

.mySR__fileIcon {
  color: #3901FF;
  height: 0.7rem;
  width: 0.7rem;
}

.mySR__fileUploadName {
  font-size: 0.8rem;
  font-weight: 500;
  color: #000000;
  margin-left: 0.5rem;
}

.mySR__removeUploadFile {
  background: none !important;
  border: none !important;
  font-size: 0.8rem !important;
  color: #2B2B2B !important;
  opacity: 0.6;
  font-weight: 500 !important;
  display: flex !important;
  justify-content: end;

}

.showLoader{
  /* display: flex;
  justify-content: center; */
  display: grid;
    place-items: center;
  /* align-items: center;
  align-self: center; */
  height: 100%;
  /* background-color: rgba(255, 44, 255, 0.1) !important; */
  /* z-index: 998; */
  /* position: fixed; */
  /* top: 0;
  left: 0; */
  width: 100%;

}

.loaderOuterBox{
  position: absolute;
  background-color: rgba(255, 44, 255, 0.1) !important;
  width: 100%;
  height: 25%;
}

.loaderInnerBox{
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

.loaderInnerBox span{
position: absolute;
}

.hideLoader{
  display: none;
}

.mySR__fileValidation{
  margin: 0px;
  color: #EF5464;
  font-size: 0.60rem;
  padding: 0.313rem 0px;
  white-space: nowrap;
}