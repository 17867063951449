.keyInformation__surveys{
    padding: 0.625rem 1rem;
}

.surveys__heading{
    margin:1.063rem;
    color:#3901FF;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
}

.surveys__table{
    border-spacing: 1.875rem;
    width: -webkit-fill-available;
    /* margin: 0.938rem  0.938rem 5rem  0.938rem ; */
    padding: 0.625rem;
    border-radius: 0.5rem;
    box-shadow: 0.078rem 0.078rem 0.334rem rgba(20, 20, 20, 0.07);
}

.surveys__theadRow{
    text-align: center;
    height: 2.813rem;
    font-style: normal;
    font-weight: 500;
    font-size: 0.627rem;
    line-height:0.75rem

}

.surveys__theadRowHeading{
    background: #D8F9FF;
    border-radius: 0.209rem 0.209rem 0rem 0rem;
}

.keyInformation__surveys  th ,td{
    border-width: 0 0.188rem !important;
    border-color:  #F5F5F5 !important;
}

.keyInformation__surveys tbody{
    background: #fff;
}

.surveys__tbodyDataRow{
    text-align: center;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 0.731rem;
    line-height:0.688rem;
}

.surveys__tbodyTableData{
    padding: 0.5rem;
}

.surveys__tbodyDataColor{
    color: #2CC26A;
}

@media (min-width:425px){
    .surveys__theadRow{
        font-size:0.75rem;
    }

    .surveys__tbodyDataRow{
      font-size: 0.875rem
    }

    .surveys__tbodyTableData{
        padding: 0.875rem;
    }
}
