.myDiary__outerBox {
    width: auto;
    padding:0.625rem 1rem;
    border-radius: 0.5rem;
    align-items: center;
}

.myDiary__heading{
    padding: 0.625rem 0rem;
    color:#3901FF;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
}

.myDiary__innerBox{
  width: 100%;
}

.myDiary__form{
  width: 100%;
}
.myDiary__inputBox{
    border: 0.043rem solid #808080;
    border-radius: 0.3rem;
    width: 100%;
    padding-left: 0.75rem;
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.731rem;
    line-height: 0.688rem;
    color: #2B2B2B;
    height: 1.7rem;
    margin-bottom: 0.6rem;
  }

  .myDiary__inputBoxError{
    border: 0.043rem solid #EF5464;
    border-radius: 0.3rem;
    width: 100%;
    padding-left: 0.75rem;
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.731rem;
    line-height: 0.688rem;
    color: #2B2B2B;
    height: 1.596rem;
  }

  .myDiary__innerBox p{
    margin: 0px;
    color: #EF5464;
    font-size: 0.731rem;
    padding: 5px 0px;
    white-space: nowrap;
  }

  .myDiary__inputBox:focus-visible{
    outline: none; 
  }

  .myDiary__textarea{
    resize: none;
    border: 0.043rem solid #808080;
    border-radius:0.3rem;  
    /* margin-top: 0.75rem; */
    width: 100%;
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.731rem;
    line-height: 0.688rem;
    color: #808080;
    padding:0.75rem;
    margin-bottom: 0.375rem;
  }

  .myDiary__textareaError{
    resize: none;
    border: 0.043rem solid #EF5464;
    border-radius:0.3rem;  
    /* margin-top: 0.75rem; */
    width: 100%;
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.668rem;
    line-height: 0.688rem;
    color: #808080;
    padding:0.75rem;
    /* margin-bottom: 0.375rem; */
  }

  .myDiary__textarea:focus{
    outline: none; 
  }
  .myDiary__validation{
    color:red
  }

.myDiary__displayFileName {
  display: flex;
  align-items: center;
  padding-left: 0.3rem;
}

.myDiary__fileIcon {
  font-size:0.75rem;
  font-weight: 600;
}

.myDiary__fileUploadName {
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 0.5rem;
}

.myDiary__removeUploadFile {
  background: none !important;
  border: none !important;
  font-size: 0.8rem !important;
  color: #2B2B2B !important;
  opacity: 0.6;
  font-weight: 500 !important;
  display: flex !important;
  justify-content: end;

}
  
  .myDiary__inputWrapper {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.668rem;
    line-height: 0.688rem;
    color: #2CC26A;
    margin-top: 0.5rem;
    text-align: end;
  }
  
  .myDiary__fileInput {
    cursor: pointer;
    height: 100%;
    position:absolute;
    top: 0;
    right: 0;
    content-visibility: hidden;
  }

  .myDiary__submitBtn{
    background: #2CC26A !important;
    border-radius: 0.3rem !important ;
    border-color: #2CC26A !important;
    color: #F9F9F9 !important;
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size:0.606rem !important;
    line-height: 0.688rem;
    white-space: nowrap;
    width: 100%;
  }

  .myDiary__submitBtn:focus-visible{
    outline: none; 
  }