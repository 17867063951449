.keyInformationQueries{
    padding: 0.625rem 1rem !important;
    width: 100%;
}

.keyInformationQueries__headingBox{
    display: flex;
    width: auto;
    padding: 0.625rem 0rem;
}

.keyInformationQueries__heading{
    flex: 1;
    color:#3901FF;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
}

.keyInformationQueries__dropdown{
    padding-right: 0.625rem;
}

/* .keyInformationQueries__dropDownButton{
    background-color: #FFFFFF !important;
    border: none !important; 
    font-weight: 500 !important;
    font-size: 0.618rem !important;
    font-family: 'Roboto' !important;
    color: #3901FF !important;
    line-height: 0.625rem !important;
}
.keyInformationQueries__dropDownButtonIcon{
    color: #000 !important;
    margin-left: 5px;
}

.keyInformationQueries__dropDownButton::after {
    display: none !important;    
} */


.keyInformationQueries__dropDownButton {
    background-color: #f5f5f5 !important;
    border: none !important;
    font-weight: 500 !important;
    font-size: 0.618rem !important;
    font-family: "Roboto" !important;
    color: #3901ff !important;
    line-height: 0.625rem !important;
    padding: 0rem !important;
  }
  
  .keyInformationQueries__dropDownButton::after {
    display: none !important;
  }
  
  .keyInformationQueries__dropdownMenu p{
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 0.489rem;
    line-height: 0.563rem;
    padding: 0.313rem 0.313rem;
    margin: 0rem;
    color: #7b7b7b;
  }
  
  .keyInformationQueries__dropdownMenu {
    --bs-dropdown-min-width: 0rem !important;
    --bs-dropdown-link-active-bg: #2CC26A !important;
    background: #f9f9f9 !important;
    box-shadow: 0.078rem 0.078rem 0.334rem rgba(20, 20, 20, 0.07) !important;
    border-radius: 0.433rem !important;
    padding: 0.625rem !important;
    color: #2CC26A !important;
  }
  
  .keyInformationQueries__dropdownItems{
    padding: 0rem !important;
    display: flex !important;
  }
  
  .keyInformationQueries__dropdownItems input{
    accent-color: green !important;
  }
  

.keyInformationQueries__outerBox{
    height: 7.813rem;
    width: auto;
    /* margin: 0.625rem 1rem; */
    padding: 0.625rem;
    border-radius: 0.5rem;
    background: #FFFFFF;
    box-shadow: 0.078rem 0.078rem 0.334rem rgba(20, 20, 20, 0.07);
}

.keyInformationQueries__innerBox{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2%;
    grid-row-gap: 20%;
    padding: 0.688rem 0.688rem 0rem 0.688rem;
}

.keyInformationQueries__innerBoxMainData{
    background: #FFF4F1;
    border-radius: 0.202rem;
    padding: 0 0.938rem;
    justify-content: space-between;
    white-space: nowrap !important;
    align-items: center;
    height:2.188rem;
    display: flex
}


.keyInformationQueries__leftText{
    font-family: Roboto;
    color: #2B2B2B;
    font-weight: 800;
    flex: 1;
    font-size: 0.563rem;
}

.keyInformationQueries__rightNumber{
    color: #FFA788;
    font-weight: 1000; 
    font-size: 0.75rem;
}

@media (max-width:425px){
    .keyInformationQueries__innerBoxMainData{
      padding: 0.5rem;
    }

}

@media (min-width:425px){
    .keyInformationQueries__leftText{
      font-size: 0.625rem;
    }

    .keyInformationQueries__rightNumber{
        font-size: 0.938rem
    }
}




