/* Outer container for components */

.marketVisitReport {
  padding: 0.625rem 1rem !important;
}

/* Heading for components */

.MarketVisitReport__headingBox {
  display: flex;
  padding: 0.425rem 0rem;
  justify-content: space-between;
}

.MarketVisitReport__heading {
  font-style: normal;
  font-weight: 600;
  font-size: 1.011rem;
  line-height: 1.188rem;
  color: #3901ff;
}

.MarketVisitReport__searchICon {
  display: flex !important;
  position: absolute;
  margin: 0.25rem 0.313rem;
  width: 0.625rem;
  height: 0.625rem;
}

.MarketVisitReport__searchInputBox {
  padding: 0rem 0rem 0rem 20px !important;
  margin: 0px !important;
  width: 100% !important;
  height: 1.125rem !important;
  border: 0.694018px solid #bcbcbc !important;
  border-radius: 2px !important;
}

/* Selection Field for components */

.MarketVisitReport__selectableRows {
  padding: 0.113rem 0px !important;
}

.MarketVisitReport__selectableRows2 {
  display: flex;
  justify-content: space-between;
  padding: 0.313rem 0px !important;
}

.MarketVisitReport__selectLabel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 0.813rem;
  color: #808080;
}

.MarketVisitReport__selectAlertMsg {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 0.813rem;
  color: #2cc26a !important;
  margin: 0px;
}

.MarketVisitReport__selectField {
  width: 100% !important;
  height: 1.7rem;
  border: 0.692008px solid #808080 !important;
  border-radius: 0.3rem !important;
  font-size: 0.75rem !important;
}

.MarketVisitReport__selectFieldLarge {
  width: 100% !important;
  height: 1.7rem;
  border: 0.692008px solid #808080 !important;
  border-radius: 0.3rem !important;
  font-size: 0.75rem !important;
}

.MarketVisitReport__selectFieldLarge option {
  width: 100% !important;
  height: 0.625rem !important;
  line-height: 0.625rem !important;
  border: 0.692008px solid #808080 !important;
  border-radius: 0.3rem !important;
  font-size: 0.75rem !important;
  color: #808080 !important;
  padding: 0px !important;
  margin: 0px !important;
}

.MarketVisitReport__selectField option {
  width: 18.5rem !important;
  height: 1.7rem !important;
  line-height: 0.625rem !important;
  border: 0.692008px solid #808080 !important;
  border-radius: 0.3rem !important;
  font-size: 0.75rem !important;
  color: #808080 !important;
}

.MarketVisitReport__selectFieldLarge:focus {
  border: 0.692008px solid #2cc26a !important;
  color: #2cc26a !important;
  border-radius: 0.3rem !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.MarketVisitReport__selectFieldLarge:visited {
  border: 0.692008px solid #2cc26a !important;
  color: #2cc26a !important;
  border-radius: 0.3rem !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.MarketVisitReport__selectField:focus {
  border: 0.692008px solid #2cc26a !important;
  color: #2cc26a !important;
  border-radius: 0.3rem !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}
.MarketVisitReport__validationErrors {
  font-size: 0.688rem;
  color: red;
  margin: 0;
}
.MarketVisitRepor__textArea {
  width: 100% !important;
  border: 0.692008px solid #808080 !important;
  border-radius: 0.3rem !important;
  font-size: 0.75rem !important;
}

.MarketVisitRepor__textArea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #f5f5f5;
}

.MarketVisitRepor__textArea::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.MarketVisitRepor__textArea::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d3d3d3;
}

.MarketVisitRepor__input textarea {
  overflow: auto !important;
}

.MarketVisitRepor__input textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 0px !important;
  background-color: #f5f5f5 !important;
}

.MarketVisitRepor__input textarea::-webkit-scrollbar {
  width: 12px !important;
  background-color: #f5f5f5 !important;
}

.MarketVisitRepor__input textarea::-webkit-scrollbar-thumb {
  border-radius: 0px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #d3d3d3 !important;
}

.myUserDetails__option {
  color: #808080 !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 0.9rem !important;
  margin-bottom: 0.6rem;
  padding-top: 2px;
  margin-right: 0.625rem;
  padding: 0.525rem;
}
.myUserDetails__radioOption {
  display: flex;
  /* padding-left: 0.625rem; */
  gap: 1rem;
}

.marketVisitReport__radioLabel {
  font-size: 0.9rem;
}

.MarketVisitReport__dateField {
  width: 100%;
  white-space: nowrap;
  height: 1.7rem;
  margin-bottom: 0.8rem;
  border: 0.692008px solid #808080;
  border-radius: 0.3rem;
  color: #808080;
  padding: 0px 5px;
}

.MarketVisitReport__down_pdf {
  white-space: nowrap;
  border-radius: 6px;
  font-size: 12px;
  background: #3901ff !important;
  border: none !important;
  height: 1.7rem;
  color: white;
}
.MarketVisitReport__downpdf_div {
  display: flex;
  align-items: center;
  justify-content: end;
}

.MarketVisitReport__inputWrapper {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.188rem;
  color: #2cc26a;
  text-align: end;
  text-decoration: underline;
  float: right;
  /* margin: 0px 0px 10px 0px; */
}

.MarketVisitReport__addMore {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.188rem;
  color: #2cc26a;
  text-align: start;
  text-decoration: underline;
  float: left;
  border: none;
  background: none;
  margin: 5px 0px 10px 0px;
}

.MarketVisitReport__fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  content-visibility: hidden;
}

/* .MarketVisitReport__inputWrapper {
  position: relative;
  overflow: hidden;
  align-items: center;
  margin: 0px;
} */

.MarketVisitReport__FileLabel {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  color: #3901ff;
  margin-top: 0.5rem;
  margin-bottom: 0.625rem;
}

.MarketVisitReport_fileInputLabel {
  display: inline-block;
  padding: 5px 5px 5px 0px;
  cursor: pointer;
  /* color: #fff; */
  border-radius: 5px;
}

.MarketVisitReport_fileInput {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.MarketVisitReport_selectedFiles {
  /* margin-top: 10px !important; */
  /* padding: 10px 10px 0px 10px; */
  /* font-size: 0.9rem; */
  color: #555;
  font-weight: 500;
  font-size: 10px;
  width: 100px;
  padding: 7px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: end;
}

.MarketVisitReport__downloadImage {
  color: #000000;
  font-size: 9px;
  font-weight: 400;
  word-wrap: break-word;
  width: 120px;
  font-weight: bolder;
  border: none;
  line-height: 11px;
  background: #eee;
  margin: 5px 5px;
}

.MarketVisitReport__downloadPDFDate {
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  padding: 5px;
  font-size: 0.85rem;
  color: #808080;
}

.MarketVisitReport__downloadPDFDate b{
  color: #3901ff;
}

.MarketVisitReport__EditButton{
  padding: 0 !important;
  width: 100%;
  background-color: #2cc26a !important;
  color: white !important;
  font-size: 13px !important;
  height: 1.8rem;
  margin-right: 10px
}


.MarketVisitReport__SubmitButton {
  padding: 0 !important;
  width: 100%;
  background-color: #3901FF !important;
  color: white !important;
  font-size: 13px !important;
  height: 1.8rem;
  margin-right: 10px
}

.MarketVisitReport__selection{
  width: 100%;
  padding: 3px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  margin-top: 5px;
}

.MarketVisitReport__selectOptions {
  padding: 3px;
  font-size: 0.688rem;
  color: #808080;
}

.MarketVisitReport__removeButton{
  float: right;
  margin-right: 5px;
}
