.pageHeader{
    width: 100%;
    height:5.124rem;
    padding:1.875rem;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height:1.188rem;
    color: #f8f8fa ;
    background: #3901ff;
    white-space: nowrap;
}

.pageHeader__backIcon {
    margin-right: 0.625rem;
    color: #F8F8FA ;
    height:1.25rem;
    width:1.25rem;
  }