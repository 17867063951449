
.exceptionDealers {
    margin: 1rem;
}

.exceptionDealers__title {
    color: #3901ff;
    font-size: 1rem;
    font-weight: 600;
}

.exceptionDealers__dealersBox {
    background: #FFFFFF;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border-radius: 8px;
    padding-bottom: 0.5rem;
}

.exceptionDealers__dissatisfiedDealers{
    font-size: 0.5rem;
    font-weight: 600;
    color: #F27042;
    position: relative;
    bottom: 0.2rem;
    left: 0.7rem;
}

.exceptionDealers__otherExceptionDealers{
    font-size: 0.5rem;
    font-weight: 600;
    color: #F27042;
    position: relative;
    bottom: 0.2rem;
    left: 0.7rem;
}

.exceptionDealers__disDealerContent{
    background: rgba(150, 150, 150, 0.13);
    margin-bottom: 0.2rem !important;
    height: 1.644rem;
    font-size: 8px;
    display: flex;
    justify-content: space-between;
}

.exceptionDealers__dealerIconName{
    display: grid;
    grid-template-columns: 25% 75%;
    align-items: center;
    margin-left: 10px;
    white-space:nowrap;
    font-weight: 600;
}
.exceptionDealers__locateIconId{
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
    margin-right:15px;
}