.container{
    margin-top: 1rem;
}

.pilSurvey_pieBlock {
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  /* margin: 0.5rem; */
  margin-bottom: 1rem;
  padding: 0rem 0.5rem 0.5rem 0.5rem;
}

.pilSurvey_pieBlockend {
  height: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  /* margin: 0.5rem; */
  padding: 0rem 0.5rem 0.5rem 0.5rem;
}

.pilSurvey_pieBlock_heading {
  text-align: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 2rem;
  background: #3901ff;
  color: #ffff;
  display: flow-root;
  align-self: center;
}

.pilSurvey_pieBlock_heading p{
    margin-top: 4px;
    font-family: 'Maven Pro',sans-serif;
    font-weight: 500;
    font-size: 0.8rem;
    white-space: nowrap;
}

.pilSurvey_pieBlock_chart{
  /* height: 20% !important;
  width: 100% !important; */
  display: flex;
  justify-content: center;
}

.pilSurvey_pieBlock canvas{
  height: 10% !important;
  width: 75% !important;
}