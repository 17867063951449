.showLoader{
  /* display: flex;
  justify-content: center; */
  display: grid;
    place-items: center;
  /* align-items: center;
  align-self: center; */
  height: 100%;
  /* background-color: rgba(255, 44, 255, 0.1) !important; */
  /* z-index: 998; */
  /* position: fixed; */
  /* top: 0;
  left: 0; */


}

.loaderOuterBox{
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3) !important;

  /* height: 25%; */
}

.loaderInnerBox{
  position: absolute;
  text-align: center;

  height: 100%;
  right: 15px;
}

.loaderInnerBox span{
position: absolute;
}


.tabComponent__header {
  width: 100%;
  height: 2.313rem;
  left: 1.313rem;
  top: 5.5rem;
  background: #3901ff;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
}

@media (min-device-width: 768px) {
  .tabComponent__header {
    height: 2.713rem;
  }
}

.tabComponent__header .nav-item button {
  font-size: 0.75rem !important;
  padding: 0.5rem 0.2rem !important;
  font-style: normal;
  font-weight: 600;
}
@media (min-device-width: 768px) {
  .tabComponent__header .nav-item button {
    font-size: 0.9rem !important;
  }
}

.tabComponent__header .nav-link.active {
  color: #ffffff !important;
  background-color: #3901ff !important;
  border-color: #3901ff !important;
  font-weight: 600;
  opacity: 1;
  border-radius: 0.75rem;
}

.tabComponent__header .nav-link {
  display: block;
  padding: 0.5rem 0.2rem;
  color: #ffff;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  font-weight: 600;
  opacity: 0.6;
}

.tabComponent__header .nav-link:hover {
  border-color: #3901ff !important;
  color: #ffff !important;
  isolation: isolate;
}

::-webkit-scrollbar {
      width: 10px;
      background: transparent;
  }

/* New Feedback tab Component css */

.newFeedback__tabHeader {
  width: 100%;
  height: 2.313rem;
  left: 1.313rem;
  top: 5.5rem;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
  --bs-nav-tabs-border-width: 0rem !important;
}

.newFeedback__tabHeader .nav-item button {
  font-size: 0.7rem !important;
  padding: 1rem 0.2rem !important;
  font-style: normal;
  font-weight: 600;
}

.newFeedback__tabHeader .nav-link.active {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem !important;
  text-align: center;
  color: #3901ff !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  text-decoration-line: underline;
  text-decoration-thickness: 0.087rem;
  text-decoration-style: solid;
  text-decoration-color: #3901ff !important;
  text-underline-offset: 0.313rem;
  /* border-bottom: 2px solid #3901ff;
  border-bottom-color: #3901ff !important; */
}

.newFeedback__tabHeader .nav-link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.606rem;
  line-height: 0.688rem;
  color: #2b2b2b !important;
}

/* Report tab Component css */

.reports__tabHeader {
  width: 100%;
  height: 2.313rem;
  left: 1.313rem;
  top: 5.5rem;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
  --bs-nav-tabs-border-width: 0rem !important;
  padding: 0rem 4.063rem 0rem 0.625rem !important;
}

.reports__tabHeader .nav-item button {
  font-size: 0.75rem !important;
  padding: 1rem 0.2rem 0.5rem 0.2rem !important;
  font-style: normal;
  font-weight: 600;
}

.reports__tabHeader .nav-link.active {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.606rem;
  text-align: center;
  color: #3901ff !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  /* text-decoration-line: underline;
  text-decoration-thickness: 0.087rem;
  text-decoration-style: solid;
  text-decoration-color: #3901ff !important;
  text-underline-offset: 0.313rem; */
  border-bottom: 2px solid #3901ff;
  border-bottom-color: #3901ff !important;
}

.reports__tabHeader .nav-link {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.606rem;
  line-height: 0.688rem;
  color: #2b2b2b !important;
}


/* Market Visit Tab components */

.MarketVisitTabs__tabHeader {
  width: 100%;
  height: 2.313rem;
  left: 1.313rem;
  top: 5.5rem;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
  --bs-nav-tabs-border-width: 0rem !important;
}

.MarketVisitTabs__tabHeader .nav-item button {
  font-size: 0.75rem !important;
  padding: 0.5rem 0.2rem !important;
  font-style: normal;
  font-weight: 600;
}

.MarketVisitTabs__tabHeader .nav-link.active {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem !important;
  text-align: center;
  color: #3901ff !important;
  background-color: #FFF !important;
  border-color: #FFF !important;
  text-decoration-line: underline;
  text-decoration-thickness: 0.087rem;
  text-decoration-style: solid;
  text-decoration-color: #3901ff !important;
  text-underline-offset: 0.313rem;
  /* border-bottom: 2px solid #3901ff;
  border-bottom-color: #3901ff !important; */
}

/* PCMS CSS */

@media (max-device-width: 770px) {
  .pcms__outerBox .pcms__iframe{
    height: 430px;
  }
}