/* Outer container for components */
.reports {
  padding: 0.625rem 1rem !important;
}

.reports__mainbox {
  background: #ffffff;
  box-shadow: 0.075rem 0.075rem 0.334rem rgba(20, 20, 20, 0.07);
  border-radius: 0.173rem;
  height: 22.50rem;
  width: 100%;
}

.reports__headingbox {
  display: flex;
  justify-content: space-between;
}

.reports__heading {
  padding: 0.625rem 0rem;
  color: #3901ff;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
}

.reports__dropdownBox {
  padding: 0.625rem 0rem;
  line-height: 0.938rem !important;
}

.reports__dropDownButton {
  padding: 0rem !important;
  border: none !important;
  background: #fff !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.625rem !important;
  line-height: 0.75rem !important;
  text-align: center !important;
  color: #3901ff !important;
}

.reports__dropDownButton::after {
  display: none !important;
}

.reports__dropdownMenu p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.489rem;
  line-height: 0.563rem;
  padding: 0.313rem 0.313rem;
  margin: 0rem;
  color: #7b7b7b;
}

.reports__dropdownMenu {
  --bs-dropdown-min-width: 0rem !important;
  --bs-dropdown-link-active-bg: #2cc26a !important;
  background: #f9f9f9 !important;
  box-shadow: 0.078rem 0.078rem 0.334rem rgba(20, 20, 20, 0.07) !important;
  border-radius: 0.433rem !important;
  padding: 0.625rem !important;
  color: #2cc26a !important;
}

.reports__dropdownItems {
  padding: 0rem !important;
  display: flex !important;
}

.reports__dropdownItems input {
  accent-color: green !important;
}

/* Today's Report section  */

.reports__todayMainBox {
  padding: 0rem;
}

.reports__todayHeaderBox {
  display: flex;
  justify-content: space-between;
}

.reports__todayHeaderTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 0.7rem;
  line-height: 0.75rem;
  padding: 0.625rem;
  color: #808080;
}

.reports__todayInnerBox {
  height: 17.375rem;
  overflow-y: scroll;
  margin-right: -9.9px;
}

.reports__todayInnerBox textarea{
  width: 100%;;
  border-left: 3px solid #969696 !important;
  background: rgba(150, 150, 150, 0.13);
  align-items: center !important;
  justify-content: space-between;
  padding: 0.313rem 0.625rem 0.313rem 0.625rem !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.7rem;
  color: #2b2b2b;
  /* border: #2b2b2b; */
  resize: none;
  /* overflow: hidden; */
  /* min-height: 60px;
  max-height: 100px; */
}

.report__todayInnerBoxEditText{
  border-color: #2cc26a ;
}

.report__todayInnerBoxText{
  border:none ;
}

.reports__todayCalenderIcon {
  width: 0.625rem;
}

.reports__todayDate {
  font-style: normal;
  font-weight: 500;
  font-size: 0.6rem;
  line-height: 0.5rem;
  padding: 0.625rem;
  color: #808080;
}

.reports__todayheading {
  padding: 0.625rem 0.6rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.6rem;
  line-height: 0.688rem;
  color: #f27042;
}

.reports__todayText {
  font-style: normal;
  font-weight: 500;
  font-size: 0.5rem;
  padding: 0.438rem;
  color: #2b2b2b;
}

.reports__todayBuuttonBox {
  padding: 0rem 0.625rem;
  display: flex;
  justify-content: end;
}

.reports__todayEditBtn {
  overflow: hidden !important;
  position: relative !important;
  cursor: pointer !important;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.668rem !important;
  line-height: 0.688rem !important;
  color: #2cc26a !important;
  text-align: end !important;
  margin-right: 0.625rem;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.reports__todaySubmitBtn {
  background: #2cc26a !important;
  border-radius: 0.173rem !important ;
  border-color: #2cc26a !important;
  color: #f9f9f9 !important;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.606rem !important;
  line-height: 0.688rem;
  white-space: nowrap;
  width: 100%;
  padding: 3px 0.625rem !important;
}

@media (min-width: 426px) {
  .reports__todayDate {
    font-size: 0.7rem;
  }

  .reports__todayHeaderTitle {
    font-size: 0.8rem;
  }
  
  .reports__todayheading {
    font-size: 0.7rem;
  }
  
  .reports__todayText {
    font-size: 0.690rem;
  }

  .reports__todayEditBtn {
    font-size: 0.8rem !important;
  }
  
  .reports__todaySubmitBtn {
    font-size: 0.8rem !important;
  }
}

/* Past Report section  */

.reports__pastMainBox {
  padding: 0rem 0.625rem;
}

.reports__pastHeader {
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: #808080;
  padding: 0.625rem;
}

.reports__pastInnerBox {
  height: 16.375rem;
  overflow-y: scroll;
  margin-right: -9.9px;
}

.reports__pastRow {
  border-left: 3px solid #969696;
  background: rgba(150, 150, 150, 0.13);
  margin: 0rem 0rem 0.5rem 0rem !important;
  align-items: center !important;
  min-height: 1.644rem;
  /* max-height: 2rem; */
  height: auto;
  padding-top: 0.438rem;
  justify-content: space-between;
  padding: 0rem 0.625rem 0rem 0.625rem;
}

.reports__pastText {
  font-style: normal;
  font-weight: 500;
  font-size: 6.92008px;
  line-height: 1.1;
  padding: 0.313rem 0rem !important;
  color: #2b2b2b;
  overflow: hidden;
  height: auto;
  align-self: center;
}

.reports__pastLocationIcon {
  width: 0.625rem;
  margin: 5px 0px;
}

.reports__pastLocation {
  font-style: normal;
  font-weight: 500;
  font-size: 6.92254px;
  line-height: 0.5rem;
  padding: 0.313rem;
  color: #808080;
  align-self: center;
}

.reports__pastBoxalign{
  display: flex;
  justify-content: end;
  padding: 0px !important;
}

.reports__pastCalenderIcon {
  width: 0.625rem;
  margin: 5px 0px;
}

.reports__pastDate {
  font-style: normal;
  font-weight: 500;
  font-size: 6.92254px;
  line-height: 0.5rem;
  padding: 0.313rem;
  color: #808080;
  align-self: center;
}

@media (min-width: 321px) {
  .reports__pastRow {
    border-left: 3px solid #969696;
    background: rgba(150, 150, 150, 0.13);
    margin: 0rem 0rem 0.5rem 0rem !important;
    align-items: center !important;
    /* min-height: 2rem;
    max-height: 3rem; */
    height: auto;
    padding-top: 0.438rem;
    justify-content: space-between;
    padding: 0rem 0.625rem 0rem 0.625rem;
  }

  .reports__pastDate {
    font-size: 0.6rem;
  }
  
  .reports__pastheading {
    font-size: 0.7rem;
  }

  .reports__pastLocation {
    font-size: 0.6rem;
  }

  .reports__pastShareTitle {
    font-size: 0.8rem !important;
  }
  
  .reports__pastText {
    font-size: 0.6rem;
  }
}

@media (min-width: 777px) {
  .reports__pastRow {
    border-left: 3px solid #969696;
    background: rgba(150, 150, 150, 0.13);
    margin: 0rem 0rem 0.5rem 0rem !important;
    align-items: center !important;
    /* min-height: 2rem;
    max-height: 3rem; */
    height: auto;
    padding-top: 0.438rem;
    justify-content: space-between;
    padding: 0rem 0.625rem 0rem 0.625rem;
  }

  .reports__pastCalenderIcon {
    width: 1rem;
  }

  .reports__pastLocationIcon {
    width: 1rem;
  }

  .reports__pastDate {
    font-size: 0.7rem;
  }

  .reports__pastHeader {
    font-size: 0.8rem;
  }

  .reports__pastLocation {
    font-size: 0.7rem;
  }
  
  .reports__pastText {
    font-size: 0.7rem;
  }
}

.reports__PastShareBox {
  display: flex;
  justify-content: end;
  padding: 0.625rem 0rem;
}

.reports__pastShareTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.606rem;
  line-height: 0.688rem;
  color: #2cc26a;
  padding: 0.5rem 0.625rem;
  display: flex;
  justify-content: end;
}

.reports__pastShareIcon {
  color: #2cc26a;
  height: 0.75rem;
  width: 0.75rem;
  background-color: #ffffff;
  margin-right: 0.313rem;
}


.reports__exclamatory_icon{
  display:flex;
  justify-content:center;
  margin-top: 6rem;
}

.reports__exclamatory_Text{
  text-align: center;
  margin-top: 0.5rem;
  font-weight: 500;
  color:grey;
}
