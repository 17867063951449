.App {
  text-align: center;
}

body,html,div,span,button,p,h6{
  font-family: 'Maven Pro',sans-serif;
}

body{
  background: #F5F5F5 !important;
  margin-bottom: 4rem !important;
}

.SessionOut_Alert{
  font-size: 13px;
  text-align: center;
  background: #d32f2f;
  color: #FFFFFF;
  padding: 5px;
}

/* maven-pro-regular - latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 400;
  src: url('./Assets/fonts/maven-pro-v32-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Assets/fonts/maven-pro-v32-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Assets/fonts/maven-pro-v32-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Assets/fonts/maven-pro-v32-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./Assets/fonts/maven-pro-v32-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Assets/fonts/maven-pro-v32-latin-regular.svg#MavenPro') format('svg'); /* Legacy iOS */
}
/* maven-pro-500 - latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 500;
  src: url('./Assets/fonts/maven-pro-v32-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Assets/fonts/maven-pro-v32-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Assets/fonts/maven-pro-v32-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Assets/fonts/maven-pro-v32-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./Assets/fonts/maven-pro-v32-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Assets/fonts/maven-pro-v32-latin-500.svg#MavenPro') format('svg'); /* Legacy iOS */
}
/* maven-pro-600 - latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 600;
  src: url('./Assets/fonts/maven-pro-v32-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Assets/fonts/maven-pro-v32-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Assets/fonts/maven-pro-v32-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Assets/fonts/maven-pro-v32-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./Assets/fonts/maven-pro-v32-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Assets/fonts/maven-pro-v32-latin-600.svg#MavenPro') format('svg'); /* Legacy iOS */
}
/* maven-pro-700 - latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 700;
  src: url('./Assets/fonts/maven-pro-v32-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Assets/fonts/maven-pro-v32-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Assets/fonts/maven-pro-v32-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Assets/fonts/maven-pro-v32-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./Assets/fonts/maven-pro-v32-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Assets/fonts/maven-pro-v32-latin-700.svg#MavenPro') format('svg'); /* Legacy iOS */
}


/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./Assets/fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Assets/fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Assets/fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Assets/fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./Assets/fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Assets/fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('./Assets/fonts/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Assets/fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Assets/fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Assets/fonts/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
       url('./Assets/fonts/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Assets/fonts/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./Assets/fonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./Assets/fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Assets/fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./Assets/fonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./Assets/fonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./Assets/fonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
