.tourPlan__messageConatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.query__messageConatiner {
  display: flex;
  height: calc(100vh - 220px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.visitDetails__OuterDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.message__text {
  text-align: center;
  margin-top: 0.5rem;
  font-weight: 500;
  color: grey !important;
}

.message__icon{
  padding-top: 15px;
}

.marketVisit__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.DealerLocatorForm__mapAccess {
  margin-top: 3rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}