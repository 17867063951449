/* Outer container for components */

.dealerLocatorForm {
  padding: 0.625rem 1rem !important;
}

/* Heading for components */

.DealerLocatorForm__headingBox {
  display: flex;
  padding: 0.625rem 0rem;
  justify-content: space-between;
}

.DealerLocatorForm__heading {
  font-style: normal;
  font-weight: 600;
  font-size: 1.011rem;
  line-height: 1.188rem;
  color: #3901ff;
}

.DealerLocatorForm__searchICon {
  display: flex !important;
  position: absolute;
  margin: 0.25rem 0.313rem;
  width: 0.625rem;
  height: 0.625rem;
}

.DealerLocatorForm__searchInputBox {
  padding: 0rem 0rem 0rem 20px !important;
  margin: 0px !important;
  width: 100% !important;
  height: 1.125rem !important;
  border: 0.694018px solid #bcbcbc !important;
  border-radius: 2px !important;
}

/* Selection Field for components */

.DealerLocatorForm__selectableRows {
  padding: 0.313rem 0px !important;
}

.DealerLocatorForm__selectableRows2 {
  display: flex;
  justify-content: space-between;
  padding: 0.313rem 0px !important;
}

.DealerLocatorForm__selectLabel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 0.813rem;
  color: #808080;
}

.DealerLocatorForm__selectField {
  width: 100% !important;
  height: 1.7rem;
  border: 0.692008px solid #808080 !important;
  border-radius: 0.3rem !important;
  font-size: 0.75rem !important;
}

.DealerLocatorForm__selectFieldLarge {
  width: 100% !important;
  height: 1.7rem;
  border: 0.692008px solid #808080 !important;
  border-radius: 0.3rem !important;
  font-size: 0.75rem !important;
}

.DealerLocatorForm__selectFieldLarge option {
  width: 100% !important;
  height: 0.625rem !important;
  line-height: 0.625rem !important;
  border: 0.692008px solid #808080 !important;
  border-radius: 0.3rem !important;
  font-size: 0.75rem !important;
  color: #808080 !important;
  padding: 0px !important;
  margin: 0px !important;
}

.DealerLocatorForm__selectField option {
  width: 18.5rem !important;
  height: 1.7rem !important;
  line-height: 0.625rem !important;
  border: 0.692008px solid #808080 !important;
  border-radius: 0.3rem !important;
  font-size: 0.75rem !important;
  color: #808080 !important;
}

.DealerLocatorForm__selectFieldLarge:focus {
  border: 0.692008px solid #2cc26a !important;
  color: #2cc26a !important;
  border-radius: 0.3rem !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.DealerLocatorForm__selectFieldLarge:visited {
  border: 0.692008px solid #2cc26a !important;
  color: #2cc26a !important;
  border-radius: 0.3rem !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.DealerLocatorForm__selectField:focus {
  border: 0.692008px solid #2cc26a !important;
  color: #2cc26a !important;
  border-radius: 0.3rem !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}
.navigate_btn {
  width: 100%;
  height: 1.5rem;
  font-size: 13px !important;
  padding: 0 !important;
  border: none !important;
  height: 1.8rem !important;
}
.DealerLocatorForm__mapSection{
  height: 14.5rem;
  width: 100%;
  background: #ededed;
  border-radius: 6.92008px;
  overflow: auto ;
  padding: 0 !important;
}