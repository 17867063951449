.DRM_Status__lable {
    color: #3901ff;
    font-size: 11.5px !important;
    margin-top: 0px;
    font-weight: 500;
    
  }
.input_meeting{
    margin-bottom: 10px;
}
.DRM_Status__lable {
    display: flex;
    flex-direction: row;
}
.status_wrap {
    display: flex;
    flex-direction: row;
}

.DRM_Container .css-19kzrtu{
    padding: 0 !important;
}

.drmPlanning__OuterBox .css-13xfq8m-MuiTabPanel-root{
    padding: 0px !important;
}
.drmPlanning__OuterBox .css-19kzrtu{
    padding: 0px !important;
}
