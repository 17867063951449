.newTourPlan__input {
  width: 100%;
  white-space: nowrap;
  height: 1.7rem;
  margin-bottom: 0.8rem;
  border: 0.692008px solid #2cc26a;
  border-radius: 0.3rem;
  color: #2cc26a;
  padding: 0px 5px;
}

.newTourPlan__inputError {
  width: 100%;
  white-space: nowrap;
  height: 1.7rem;
  /* margin-bottom: 0.8rem; */
  border: 0.692008px solid #ef5464;
  border-radius: 0.3rem;
  color: #808080;
  padding: 0px 5px;
}

.newTourPlan__inputError:focus-visible {
  outline: #ef5464 auto 1px;
}

.newTourPlan__input:focus-visible {
  outline: #2cc26a auto 1px;
}

.newTourPlan__save {
  color: #3901ff;
  font-size: 11.5px !important;
  border: none;
  margin-top: 0px;
  justify-self: right;
  font-weight: 500;
  background: none;
}
.newTourPlan__submit {
  width: 4.5rem;
  background: #3901ff !important;
  border: none !important;
  height: 1.7rem;
  place-self: center;
  justify-self: right;
  padding: inherit !important;
}
.newTourPlan__name {
  color: #3901ff;
  font-weight: 600;
  margin-top: 5px;
}
.newTourPlan__heading {
  margin-bottom: 1.5rem;
  padding: 0.8rem;
}
.newTourPlan__submitBtn {
  font-size: 11.5px;
  margin-top: 0.3rem;
}
.newTourPlan__submit:hover {
  background: #3901ff !important;
  color: white !important;
}
.submittedIcons .submittedLarge {
  font-size: 3rem;
  color: #ffa600;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.submittedIcons .submittedSmall {
  font-size: 1.5rem;
  color: #ffa60033;
  margin-top: 1rem;
}
.draftIcons .draftLarge {
  font-size: 3rem;
  color: #2cc26a;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.draftIcons .draftSmall {
  font-size: 1.5rem;
  color: #ffa60033;
  margin-top: 1rem;
}
.draftIcons.submittedIcons {
  align-self: center;
  margin-top: 2rem;
}
.newTourPlan_formData {
  font-size: 0.8rem;
  color: #808080;
  box-shadow: 0.9px 0.9px 4.86px rgb(20 20 20 / 25%);
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 5px;
}

.newTourPlan_formData p {
  margin: 0px;
  color: #ef5464;
  font-size: 0.6rem;
  /* padding: 5px 0px; */
  white-space: nowrap;
  position: relative;
    bottom: 0.6rem;
}

.newTourPlan__delete {
  border: 0;
  width: 5rem;
  background: transparent;
  justify-self: left;
  font-size: 13px;
  color: black;
  white-space: nowrap;
}
.newTourPlan__copy {
  border: 0;
  width: 5rem;
  background: transparent;
  justify-self: left;
  font-size: 13px;
  margin-left: -17px;
  color: black;
  font-weight: 400;
}
.newTourPlan__formButtons {
  display: grid;
  grid-template-columns: 20% 25% 30% 25%;
}
.newTourPlan__defaultOption {
  display: none;
}

.newTourPlan__inputs_Other{
  color: #2cc26a !important;
  border: 0.043rem solid #2cc26a !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  background-color: #ffffff;
  font-size: 0.731rem !important;
  margin-bottom: 0.6rem;
  min-height: 1.7rem !important;
  max-height: auto !important;
  font-size: 0.523rem;
  border-radius: 0.3rem;
  top: -1px;
  width: 100%;
  padding-left: 10px !important;
}

.newTourPlan__inputs_Other:focus {
  outline: none;
}

.newTourPlan__inputs_Other::placeholder {
  color: #2cc26a !important;
}

.UpcomigTourDetailsModal__value{
    color: #2cc26a !important;
    font-size: 0.75rem;
    padding-left: 10px;
}

.UpcomigTourDetailsModal__value_Li {
  color: #2cc26a !important;
  font-size: 0.75rem;
}

.UpcomigTourDetailsModal__MainBox{
  display: flex;
}


.UpcomigTourDetailsModal__MainBox ol{
  margin: 0px !important;
}

.UpcomigTourDetailsModal__MainBox label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #808080;
}

.UpcomigTourDetailsModal__Header {
  color: #3901ff;
  font-weight: 600;
  margin-top: 0.5rem;
}


/* --------------------- */

.errorIcons.submittedIcons {
  align-self: center;
  margin-top: 2rem;
}

.errorIcons .errorLarge {
  font-size: 3rem;
  color: #EF5464;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.errorIcons .errorSmall {
  font-size: 1.5rem;
  color: #EF5464;
  margin-top: 1rem;
}