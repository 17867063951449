.newFeedback {
  padding: 0.625rem 1rem;
  width: 100%;
}

.newFeedback__mainbox {
  background: #ffffff;
  box-shadow: 0.075rem 0.075rem 0.334rem rgba(20, 20, 20, 0.07);
  border-radius: 0.3rem;
}

.newFeedback__heading {
  padding: 0.625rem 0px;
  color: #3901FF;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
}

.newFeedback__outerInputBox {
  width: auto;
  padding: 0.625rem;
  border-radius: 0.5rem;
  align-items: center;
}

.newFeedback__outerInputBox p {
  margin: 0px;
  color: #EF5464;
  font-size: 0.60rem;
  padding: 5px 0px;
  white-space: nowrap;
}

.newFeedback__heading {
  padding: 0.625rem 0px;
  color: #3901ff;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
}

.newFeedback_defaultOption {
  display: none;
}

.newFeeback__delaerSelect {
  background-image: url(../../../Assets/Images/downArrow.png) !important;
  border: 0.043rem solid #808080 !important;
  border-radius: 0.3rem !important;
  color: #808080 !important;
  font-family: 'Roboto' !important;
  font-weight: 400;
  font-size: 0.731rem !important;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  line-height: 0.688rem !important;
}

.newFeeback__delaerSelect:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

.newFeeback__delaerSelectError {
  background-image: url(../../../Assets/Images/downArrow.png) !important;
  border: 0.043rem solid #EF5464 !important;
  border-radius: 0.3rem;
  color: #808080 !important;
  font-family: 'Roboto' !important;
  font-weight: 400;
  font-size: 0.731rem !important;
  line-height: 0.688rem !important;
  /* margin-top: 0.75rem */
}

.newFeedback__inputBox {
  /* margin-top: 0.75rem; */
  border: 0.043rem solid #808080;
  border-radius: 0.3rem;
  width: 100%;
  padding-left: 0.75rem;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.731rem !important;
  line-height: 0.688rem;
  color: #2b2b2b;
  height: 1.596rem;
  margin-bottom: 0.75rem;
}

.newFeedback__inputBoxError {
  /* margin-top: 0.75rem; */
  border: 0.043rem solid #EF5464;
  border-radius: 0.3rem;
  width: 100%;
  padding-left: 0.75rem;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.731rem !important;
  line-height: 0.688rem;
  color: #2b2b2b;
  height: 1.596rem;
}

.newFeedback__inputBox:focus {
  outline: none;
}

.newFeedback__textarea {
  resize: none;
  border: 0.043rem solid #808080;
  border-radius: 0.3rem;
  /* margin-top: 0.75rem; */
  width: 100%;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.731rem !important;
  line-height: 0.688rem;
  color: #808080;
  padding: 0.75rem;
}

.newFeedback__textareaError {
  resize: none;
  border: 0.043rem solid #EF5464;
  border-radius: 0.3rem;
  /* margin-top: 0.75rem; */
  width: 100%;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.731rem !important;
  line-height: 0.688rem;
  color: #808080;
  padding: 0.75rem;
}

.newFeedback__textarea:focus {
  outline: none;
}

.newFeedback__inputWrapper {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.668rem;
  line-height: 0.688rem;
  color: #2cc26a;
  margin-top: 0.5rem;
  text-align: end;
  margin-right: 0.625rem;
}

.newFeedback__fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
  content-visibility: hidden;
}

.newFeedback__submitBtn {
  background: #2cc26a !important;
  border-radius: 0.3rem !important;
  border-color: #2cc26a !important;
  color: #f9f9f9 !important;
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.606rem !important;
  line-height: 0.8rem;
  white-space: nowrap;
  width: 90%;
  padding: 2px !important;
}

.newFeedback__shareIcon {
  color: #2cc26a;
  height: 0.75rem;
  width: 0.75rem;
  background-color: #ffffff;
  margin-right: 0.313rem;
}

.newFeedback__displayFileName {
  display: flex;
  align-items: center;
  padding-left: 0.3rem;
}

.newFeedback__fileIcon {
  font-size:0.75rem;
  font-weight: 600;
}

.newFeedback__fileUploadName {
  font-size: 0.8rem;
  font-weight: 500;
  color: #000000;
  margin-left: 0.5rem;
}

.newFeedback__removeUploadFile {
  background: none !important;
  border: none !important;
  font-size: 0.8rem !important;
  color: #2B2B2B !important;
  opacity: 0.6;
  font-weight: 500 !important;
  display: flex !important;
  justify-content: end;

}

.newFeedback__shareTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.606rem !important;
  line-height: 0.688rem !important;
  color: #2cc26a !important;
  margin-top: 0.5rem !important;
}

@media (min-width: 426px) {

  .newFeedback__shareTitle {
    font-size: 0.8rem;
  }

  .newFeedback__submitBtn {
    font-size: 0.8rem !important;
    line-height: 1.1rem;
  }

  .newFeedback__inputWrapper {
    font-size: 0.8rem;
  }

}