.previousObservation__box {
  /* padding: 0.625rem 1rem; */
}

.previousObservation__heading {
  color: #3901ff;
  font-weight: 600;
  margin-top: 1rem;
}

.previousObservation__count {
  display: flex;
  justify-content: end;
  font-weight: 500;
  font-size: 13.4606px !important;
  color: #f27042;
  white-space: nowrap;
}

.previousObservation__toDay {
  display: flex;
  justify-content: start;
  color: #808080;
  font-weight: 500;
  font-size: 0.841rem !important;
}
.previousObservation__location {
  display: flex;
  gap: 0.5rem;
  font-weight: 500;
  font-size: 0.62rem;
  color: #808080;
  font-family: "Maven Pro";
  padding-left: 0rem !important;
  margin-left: 0.813rem;
  gap: 0.7rem;
}
.previousObservation__FeedbackText {
  border-left: 3px solid #969696;
  background: rgba(150, 150, 150, 0.13);
  margin-bottom: 0.5rem !important;
  align-items: center !important;
  min-height: 1.644rem;
  max-height: auto;
  /* padding-top: 0.438rem; */
}

.previousObservation__FeedbackText p {
  font-weight: 500;
  font-size: 0.5rem;
  color: #2b2b2b;
  font-family: "Maven Pro";
  height: auto;
}
.previousObservation__seeAll {
  font-family: "Roboto" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.558rem;
  color: #2cc26a;
  white-space: nowrap;
  margin-bottom: 0px !important;
}

.previousObservation__bottom_section {
  display: flex;
}
.previousObservation__seeAll_icon {
  color: hsla(145, 63%, 47%, 1);
  height: 0.813rem;
  width: 0.625rem;
  margin-left: 0.3rem;
  stroke-width: 1;
}

.previousObservation__bottom_button {
  background: white;
  border: none;
  padding-left: 0;
  display: flex;
  text-align: center;
}
.previousObservation__dataDiv {
  /* min-height: 4rem; */
  max-height: 193px;
  overflow: auto;
}
.previousObservation__FeedbackText_p {
  line-height: 1.1;
  padding: 0.625rem;
  align-self: center;
  font-weight: 500;
  font-size: 0.6rem;
  color: #2b2b2b;
  font-family: "Maven Pro";
  height: auto;
}
