.pilTable {
  padding: 0.625rem 0.625rem 0rem 0.625rem !important;
}

.pilTable__box {
  /* height: 20rem; */
  overflow: scroll;
  margin-right: -10px;
  height: auto;
}

.pilTable__heading {
  padding: 0.625rem 0rem;
  color: #3901ff;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.625rem 1rem;
}

.pilTable__table {
  border-spacing: 1.875rem;
  width: -webkit-fill-available;
  /* margin: 0.938rem  0.938rem 1rem  0.938rem ; */
  padding: 0.625rem;
  border-radius: 0.5rem;
  box-shadow: 0.078rem 0.078rem 0.334rem rgba(20, 20, 20, 0.07);
}

.pilTable__theadRow {
  text-align: center;
  height: 2.813rem;
  font-style: normal;
  font-weight: 500;
  font-size: 0.627rem;
  line-height: 0.75rem;
}

.pilTable__theadRowHeading {
  background: #d8d9da;
  border-radius: 0.35rem 0.35rem 0 0;
  border-width: 0 3px !important;
  /* border-color: white; */
  font-size: 0.8rem;
}

.pilTable__table td {
  border-width: 0 0.188rem !important;
  border-color: #f5f5f5 !important;
  padding: 2px;
  font-size: 0.8rem;
}

.pilTable__table tr {
  border: 0.188rem solid #f5f5f5;
}
.pilTable__table tbody {
  background: #fff;
}

.pilTable__tbodyDataRow {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 0.731rem;
  line-height: 0.688rem;
}

.pilTable__tbodyTableData {
  padding: 0.3rem;
  font-size: 10px;
  font-weight: 400;
}

@media (min-width: 425px) {
  .pilTable__theadRow {
    font-size: 0.75rem;
  }

  .pilTable__tbodyDataRow {
    font-size: 0.875rem;
  }
}
.pilTable__tbodyDataRow input[type="checkbox"] {
  width: 13px;
  border-color: black !important;
  margin-left: -15px !important;
}
.pilTable__tbodyDataRow input[type="checkbox"] + label {
  margin-top: 3px;
}
.pilTable__tbodyDataRow input[type="checkbox"]:checked {
  background-color: #2cc26a !important;
  border-color: rgba(197, 255, 221, 0.35) !important;
}
.pilTable__tbodyDataRow input[type="checkbox"]:focus {
  box-shadow: none !important;
  border-color: black !important;
}

.tr_hightlight {
  background: rgba(197, 255, 221, 0.35);
}
.pilTable__table .form-check {
  padding-left: 2em;
}

.pilTable__input{
    width: 3rem;
}

.myDealer__navButton {
    background: #2cc26a !important;
    border-radius: 2.76803px !important;
    border-color: #2cc26a !important;
    font-family: "Roboto" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 9.68811px !important;
    color: #f9f9f9 !important;
    line-height: 0.8rem !important;
    white-space: nowrap !important;
    width: 100% !important;
    padding: 4px !important;
  }