/* My Dealer Searchable select css */

.myDealer__categorySelect .MuiOutlinedInput-root {
  color: #808080 !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  /* background-color: #f5f5f5; */
  background-color: #fff;
  font-size: 0.731rem !important;
  margin-bottom: 0.6rem;
  min-height: 1.7rem !important;
  max-height: auto !important;
  padding: 0px !important;
  font-size: 0.523rem;
  border-radius: 0.3rem;
  margin-bottom: 0.6rem;
  /* border: 1px solid #808080 !important; */
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option {
  font-size: 0.75rem;
  padding-left: 18px !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  min-height: 32px !important;
}

.myDealer__categorySelect .MuiOutlinedInput-root input {
  font-size: 0.75rem;
  position: absolute;
  width: 100% !important;
  padding: 0.375rem 0rem 0.375rem 0.75rem !important;
}

.myDealer__categorySelect .MuiAutocomplete-input::placeholder {
  color: #808080 !important;
  font-weight: 400 !important;
  opacity: 1 !important;
}

.myDealer__categorySelect .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -8px;
  font-size: 0.75rem;
  color: #2cc26a;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: #2cc26a !important;
  background-color: #f5f5f5;
  padding: 0 0.313rem !important;
  top: 0px !important;
}

.myDealer__categorySelected
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root
  .Mui-focused {
  color: #2cc26a !important;
}

.css-1q79v3g-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  background-image: none !important;
}

.myDealer__categorySelect .css-i4bv87-MuiSvgIcon-root {
  background-image: url(../../Assets/Images/downArrow.png);
  color: #808080 !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 0.731rem !important;
  background-size: 16px;
  top: 2px;
  width: 1.5em;
  /* height: 1.5em; */
  position: inherit;
}

.myDealer__categorySelect .css-vubbuv {
  background-image: url(../../Assets/Images/downArrow.png);
  color: #808080 !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 0.731rem !important;
  background-size: 16px;
  top: 2px;
  width: 1.5em;
  /* height: 1.5em; */
  position: inherit;
}

.myDealer__categorySelect .css-i4bv87-MuiSvgIcon-root path {
  display: none !important;
}

.myDealer__categorySelect .css-vubbuv path {
  display: none !important;
}

.myDealer__categorySelect .MuiAutocomplete-tag svg{
  display: none;
}

/* New tour Searchable select css */

.newTourPlan__inputs .MuiOutlinedInput-root {
  color: #2cc26a !important;
  border: 0.043rem solid #2cc26a !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  background-color: #ffffff;
  font-size: 0.731rem !important;
  margin-bottom: 0.6rem;
  height: 1.7rem !important;
  padding: 0px !important;
  font-size: 0.523rem;
  border-radius: 0.3rem;
  top: -1px;
  /* border: 1px solid #2cc26a !important; */
}

.newTourPlan__inputs .MuiAutocomplete-option {
  font-size: 0.75rem;
  padding-left: 18px !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  min-height: 32px !important;
}

.newTourPlan__inputs .MuiOutlinedInput-root input {
  font-size: 0.75rem;
  position: absolute;
  width: 80% !important;
  padding: 0.375rem 0rem 0.375rem 0.75rem !important;
}

.newTourPlan__inputs .MuiAutocomplete-input::placeholder {
  color: #2cc26a !important;
  font-weight: 400 !important;
  opacity: 1 !important;
  height: auto;
}

.newTourPlan__inputs .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -8px;
  font-size: 0.75rem;
  color: #2cc26a;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: #2cc26a !important;
  background-color: #ffffff;
  padding: 0 0.313rem !important;
  top: 0px !important;
}

.myDealer__categorySelected
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root
  .Mui-focused {
  color: #2cc26a !important;
}

.newTourPlan__inputs .css-i4bv87-MuiSvgIcon-root {
  background-image: url(../../Assets/Images/downArrow.png) !important;
  color: #808080 !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 0.731rem !important;
  background-size: 16px;
  width: 1.5em;
  height: 1.7em;
  position: inherit;
  margin-top: 4px;
}

.newTourPlan__inputs .css-vubbuv {
  background-image: url(../../Assets/Images/downArrow.png) !important;
  color: #808080 !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 0.731rem !important;
  background-size: 16px;
  width: 1.5em;
  height: 1.7em;
  position: inherit;
  margin-top: 4px;
}

.newTourPlan__inputs .css-i4bv87-MuiSvgIcon-root path {
  display: none !important;
}

.newTourPlan__inputs .css-vubbuv path {
  display: none !important;
}

.newTourPlan__inputs:focus-visible {
  outline: none !important;
  outline-style: none !important;
}

.newTourPlan__inputs .MuiButtonBase-root {
  margin-right: -10px;
}

.newTourPlan__inputs .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  position: fixed !important;
}

.newTourPlan__inputs .css-igs3ac {
  position: fixed !important;
}

.css-1q79v3g-MuiButtonBase-root-MuiChip-root {
  height: 20px !important;
  margin: 5px !important;
}


/* new multiple  */

.newTourPlan__inputs_multiple .MuiOutlinedInput-root {
  color: #2cc26a !important;
  border: 0.043rem solid #2cc26a !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  background-color: #ffffff;
  font-size: 0.731rem !important;
  margin-bottom: 0.6rem;
  min-height: 1.7rem !important;
  max-height: auto !important;
  padding: 0px !important;
  font-size: 0.523rem;
  border-radius: 0.3rem;
  top: -1px;
  /* border: 1px solid #2cc26a !important; */
}

.newTourPlan__inputs_multiple .MuiAutocomplete-option {
  font-size: 0.75rem;
  padding-left: 18px !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;
  min-height: 32px !important;
}

.newTourPlan__inputs_multiple .MuiOutlinedInput-root input {
  font-size: 0.75rem;
  position: absolute;
  width: 80% !important;
  padding: 0.375rem 0rem 0.375rem 0.75rem !important;
}

.newTourPlan__inputs_multiple .MuiAutocomplete-input::placeholder {
  color: #2cc26a !important;
  font-weight: 400 !important;
  opacity: 1 !important;
  height: auto;
}

.newTourPlan__inputs_multiple .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  top: -8px;
  font-size: 0.75rem;
  color: #2cc26a;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: #2cc26a !important;
  background-color: #ffffff;
  padding: 0 0.313rem !important;
  top: 0px !important;
}

.myDealer__categorySelected
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root
  .Mui-focused {
  color: #2cc26a !important;
}

.newTourPlan__inputs_multiple .MuiIconButton-root .css-i4bv87-MuiSvgIcon-root {
  background-image: url(../../Assets/Images/downArrow.png) !important;
  color: #808080 !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 0.731rem !important;
  background-size: 16px;
  width: 1.5em;
  height: 1.7em;
  position: inherit;
  margin-top: 4px;
}

.newTourPlan__inputs_multiple .MuiIconButton-root .css-vubbuv {
  background-image: url(../../Assets/Images/downArrow.png) !important;
  color: #808080 !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 0.731rem !important;
  background-size: 16px;
  width: 1.5em;
  height: 1.7em;
  position: inherit;
  margin-top: 4px;
}

.newTourPlan__inputs_multiple .MuiIconButton-root .css-i4bv87-MuiSvgIcon-root path {
  display: none !important;
}

.newTourPlan__inputs_multiple .MuiIconButton-root .css-vubbuv path {
  display: none !important;
}

.newTourPlan__inputs_multiple:focus-visible {
  outline: none !important;
  outline-style: none !important;
}

.newTourPlan__inputs_multiple .MuiButtonBase-root {
  margin-right: -10px;
}

.newTourPlan__inputs_multiple .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  position: fixed !important;
}

.newTourPlan__inputs_multiple .css-igs3ac {
  position: fixed !important;
}