.currentDayInformation{
  padding: 0.625rem 1rem !important;
}

.currentDayInformation__heading {
    padding: 0.625rem 0rem;
    color: #3901ff;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .currentDayInformation__outerBox {
    height: 6rem;
    width: auto;
    /* margin: 0.938rem; */
    padding: 0.625rem 1rem;
    border-radius: 0.5rem;
    background: #ffffff;
    text-align: center;
    box-shadow: 0.078rem 0.078rem 0.334rem rgba(20, 20, 20, 0.07);
  }
  
  .currentDayInformation__dataMainBox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.938rem;
    justify-items: center;
    padding-top: 0.25rem;
  }
  
  .currentDayInformation__data {
    background: #edfff5;
    display: flex;
    width: 100%;
    border-radius: 0.202rem;
    padding: 0.438rem;
  }
  
 
  .currentDayInformation__leftData {
    padding: 0.3rem;
    flex: 1;
  }

  .currentDayInformation__leftDataFirst {
    font-family: "Roboto" !important;
    font-weight: 700;
    color: #2b2b2b;
    font-size: 0.75rem;
  }
   
  .currentDayInformation__leftDataSecond {
    font-family: "Roboto" !important;
    font-weight: 500;
    color: #9b9b9b;
    font-size: 0.5rem;
  }
  
  .currentDayInformation__rightData {
    font-weight: 800;
    color: #24e774;
    font-size: 0.938rem;
    padding: 0.313rem 0.938rem;
  }

  @media (max-device-width: 375px) and (min-device-width: 325px) {
    .currentDayInformation__data {
      padding: 0.35rem;
    }
  }

  @media (max-width: 370px) {
    .currentDayInformation__leftData {
      padding: 0.125rem !important;
    }
  }
  
  @media (min-width: 425px) {
    .currentDayInformation__outerBox {
      width: 96%;
    }
  }

  @media (max-device-width: 660px) and (min-device-width: 520px) {
    .currentDayInformation__leftDataFirst {
        font-size: 0.563rem;
      }
  }
  
  @media (max-device-width: 520px) {
    .currentDayInformation__dataMainBox {
      grid-gap: 0.313rem;
    }
  
    .currentDayInformation__leftDataFirst {
      font-size: 0.6rem;
    }
  
    .currentDayInformation__leftDataSecond {
      font-size: 0.5rem;
    }
  
    .currentDayInformation__rightData {
      font-size: 1rem !important;
      padding: 0.625rem 0.313rem !important;
    }
  }