.myWssCount__dropdownBox {
    align-self: center;
}

.myWssCount__name {
    color: #3901ff;
    font-weight: 600;
}

.myWssCount__drop {
    background: transparent !important;
    color: white !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    border: none !important;
}

.myWssCount__box {
    width: auto;
    /* height: 15rem; */
    height: 100%;
    margin-bottom: 0.5rem;
}

.myWssCount__drop::after {
    content: none !important;
}

.myWssCount__dropdownMenu {
    width: 6.5rem !important;
    box-shadow: 0.9px 0.9px 3.86px rgb(20 20 20 / 7%);
    border: 0 !important;
    width: 50%;
    transform: translate3d(35px, 30px, 0px) !important;
    inset: 0px auto auto 0px !important;
}

.myWssCount__dropdownItem {
    font-size: 10.5px;
    padding: 2px 6px !important;
    display: flex !important;
    width: 130% !important;
}

.myWssCount__dropdownItem .dropdown-item:focus,
.dropdown-item:hover {
    background-color: white !important;
}

.myWssCount__dropdownItem .form-check {
    min-height: 1.3rem;
    display: block;
    min-height: 0rem;
    padding-left: 0em;
    margin-bottom: 0rem;
}

/* .myWssCount__dropdownItem__formCheckInput_checked {
    background-color: #2CC26A !important;
    border: 0.456682px solid #C7C8CA !important;
} */
.myWssCount__selectedValue {
    font-size: 12px;
}

.myWssCount__inputRadioButton input {
    width: 15px !important;
    height: 15px !important;
    vertical-align: text-bottom;
}

.myWssCount__inputRadioButton input:checked {
    background-color: #2cc26a !important;
    border-color: #2cc26a !important;
    vertical-align: text-bottom;
}

.myWssCount__dropdownItem label {
    margin-left: 5px;
    align-self: center;
    padding: 5px 0px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 11.83px;
    line-height: 10px;
    display: block;
}

.myWssCount__inputradioBox {
    padding: 0px 10px 0 5px;
    /* margin-left: 12px; */
}

.myWssCount__drop .dropdown-menu.show {
    display: block;
}


@media screen and (min-width: 770px) {
    .myWssCount__inputRadioButton label {
        margin-left: 12px;
        align-self: center;
        padding: 4px 0px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14.83px;
        line-height: 20px;
        display: block;
    }

    .myWssCount__dropdownMenu {
        width: 6rem !important;
        inset: 0px auto auto -35px !important;
    }

    /* .myWssCount__inputradioBox {
        padding: 0px 20px 0 10px;
        margin-left: 12px;
    } */

    .myWssCount__inputRadioButton input {
        width: 20px !important;
        height: 20px !important;
    }
    


}

@media screen and (max-width: 770px) and (min-width: 426px) {
    .myWssCount__dropdownMenu {
        inset: 0px auto auto 40px !important;
    }
}

@media screen and (max-width: 425px) and (min-width: 376px) {
    .myWssCount__dropdownMenu {
        inset: 0px auto auto 20px !important;
    }
}