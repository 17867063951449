.parentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3) !important;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.overlay-box {
  position: absolute;
  top: 41% !important;
  left: 38% !important;
  /* display: grid;
  place-items: center;
  height: 100%;
  position: relative; */
}

.loader__loaderImg{
  position:absolute;
  /* margin-left: 15px;
  margin-bottom: 15px; */
  width: 3rem;
  margin: 17px 12px 12px 21px;
}

/* .overlay-box img {
  position: fixed;
  top: 43% !important;
  left: 41% !important;
  width: 3rem !important;
  z-index: 1200;

} */

.hideLoader {
  display: none;
}
