
/* .modal-backdrop.fade{
    opacity: 0 !important;
} */
.modalPopup1{
    top: 30% !important;
}
.modalPopup1 .modal-content{
    border: 0!important;
    border-radius: 7px;
    box-shadow: 1.27926px 5.27926px 20.48661px rgb(35 35 35 / 10%);
}
.modalPopup1 .modalPopupTitle{
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}
.modalPopup1 .modalPopupSubTitle{
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    margin-top: -10px;
    color: #808080;
}
.modalPopup1 .modalPopupData{
    font-size: 12px;
    display: flex;
    justify-content: center;
}

.myWssFeedbackForm{
    top: 10% !important;
}

.UpcomigTourDetailsModal {
    top: 25% !important;
}

.editForm_modal {
    top: 15% !important;
}

@media (max-width:565px){
    .MyDealerSummaryModal .modal-dialog{
        margin:0 3.5rem !important;
    }

    .modal.show .modal-dialog{
        margin:0 1.5rem;
    }
    
    .UpcomigTourDetailsModal{
        top: 25% !important;
    }
    .myWssFeedbackForm{
        top: 10% !important;
    }
    .editForm_modal{
        top: 10% !important;
    }
}

.divisionGroup__OuterBox .modalPopupData{
    font-size: 12px;
    display: block;
  }