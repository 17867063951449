/* Outer container for components */
.cwp {
  padding: 0.625rem 1rem !important;
}

/* Selection Field for components */

.cwp__selectableRows {
  padding: 0.313rem 0px !important;
}

.cwp__selectableRows2 {
  display: flex;
  justify-content: space-between;
  padding: 0.313rem 0px !important;
}

.cwp__selectLabel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.688rem;
  line-height: 0.813rem;
  color: #808080;
}

.cwp__selectField {
  width: 100% !important;
  height: 1.5rem;
  border: 0.692008px solid #808080 !important;
  border-radius: 0.173rem !important;
  font-size: 0.75rem !important;
}

.cwp__selectFieldLarge {
  width: 100% !important;
  height: 1.5rem;
  border: 0.692008px solid #808080 !important;
  border-radius: 0.173rem !important;
  font-size: 0.75rem !important;
}

.cwp__selectFieldLarge option {
  width: 100% !important;
  height: 0.625rem !important;
  line-height: 0.625rem !important;
  border: 0.692008px solid #808080 !important;
  border-radius: 0.173rem !important;
  font-size: 0.75rem !important;
  color: #808080 !important;
  padding: 0px !important;
  margin: 0px !important;
}

.cwp__selectField option {
  width: 18.5rem !important;
  height: 1.5rem !important;
  line-height: 0.625rem !important;
  border: 0.692008px solid #808080 !important;
  border-radius: 0.173rem !important;
  font-size: 0.75rem !important;
  color: #808080 !important;
}

.cwp__selectFieldLarge:focus {
  border: 0.692008px solid #2cc26a !important;
  color: #2cc26a !important;
  border-radius: 0.173rem !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.cwp__selectFieldLarge:visited {
  border: 0.692008px solid #2cc26a !important;
  color: #2cc26a !important;
  border-radius: 0.173rem !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

.cwp__selectField:focus {
  border: 0.692008px solid #2cc26a !important;
  color: #2cc26a !important;
  border-radius: 0.173rem !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%) !important;
}

/* Inner Small Boxes for components */

.cwp__outerBox {
  padding: 0.938rem 0.938rem;
  margin-top: 1.875rem;
  display: flex;
  justify-content: space-between;
}

.cwp__InnerBox {
  /* width: 112.64px; */
  /* height: 6.25rem; */
  background: #f9f9f9;
  box-shadow: 0.9px 0.9px 3.86px rgba(20, 20, 20, 0.07);
  border-radius: 0.938rem;
  text-align: center;
  align-self: center;
  margin-bottom: 1.563rem;
}

.cwp__Heading {
  /* width: 113px; */
  height: 38px;
  background: #3901ff;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  box-shadow: 0.9px 0.9px 3.86px rgba(20, 20, 20, 0.07);
  border-top-left-radius: 0.938rem;
  border-top-right-radius: 0.938rem;
  color: #f9f9f9;
  padding: 0.625rem;
}

.cwp__InnerBox p {
  margin: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.625rem;
  color: #3901ff;
  padding: 0.938rem;
}

.cwp_DetailsRow{
  display: flex;
  justify-content: space-between;
  height: 1.563rem;
}

.cwp__DetailsName{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 9px;
  color: #2B2B2B;
  text-align: center;
  align-self: center;
}

.cwp__DetailsValue{
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #3901ff;
}

.cwp_DetailsBox{
  padding: 10px;
}