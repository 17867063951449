.input_field_outer {
    padding: 0.113rem 0px !important;
    
}
.Free_input {
    margin-bottom: 0.6rem;
}

.DRM_Form_label {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 0.688rem;
    line-height: 0.813rem;
    color: #808080;
}

.DRM_Form_text_input {
    width: 100% !important;
    height: 1.7rem;
    border: 0.692008px solid #808080 !important;
    border-radius: 0.3rem !important;
    font-size: 0.75rem !important;
    background-color: white;
}

.DRM_Form_text_input:internal-autofill-selected {
    background-color : white !important;
}

.DRM_Form_text_input:input:disabled {
    background-color : white !important;
}

.QrCode {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; 
}
.DRM_QR {
    display: flex;
    justify-content: end;
}

.QRimage {
    height: 60px;
    width: 60px;
}

.QrImage{
    height: 300px !important;
    width: 300px !important;
}
.start_meet {
    background: #3901ff !important;
    border: none !important;
    height: 1.9rem;
    place-self: center;
    justify-self: right;
    /* padding: inherit !important; */
    border-radius: 5px !important;
    color: white;
    width: 30%;

}
.start_meeting_text {
    font-size: 13.5px;
    margin-bottom: 0px;
}

.file_instruction {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 1.188rem;
    color: #2cc26a;
    margin-top: 0.5rem;
    text-align: end;
    margin-right: 0.625rem;
    /* text-decoration: underline; */
    float: right;
}

.file_instruction {
    position: relative;
    overflow: hidden;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin: 0px;
  }

/* .css-19kzrtu {
    padding: 0 !important;
} */

/* ----------------------------------- Bug Fixed --------------------------------------- */
.drm__Innerbox {
    padding: 0.625rem 0rem !important;
}

.drm__categorySelect .MuiOutlinedInput-root {
    color: #808080 !important;
    font-family: "Roboto" !important;
    font-weight: 400;
    /* background-color: #f5f5f5; */
    background-color: #fff;
    font-size: 0.731rem !important;
    margin-bottom: 0.6rem;
    min-height: 1.7rem !important;
    max-height: auto !important;
    padding: 0px !important;
    font-size: 0.523rem;
    border-radius: 0.3rem;
    margin-bottom: 0.6rem;
    /* border: 1px solid #808080 !important; */
}

.drm__categorySelect .MuiOutlinedInput-root input {
    font-size: 0.75rem;
    position: relative;
    width: 100% !important;
    padding: 0.375rem 0rem 0.375rem 0.75rem !important;
}

.drm__categorySelect .MuiAutocomplete-input::placeholder {
    color: #808080 !important;
    font-weight: 400 !important;
    opacity: 1 !important;
}

.drm__categorySelect .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    top: -8px;
    font-size: 0.75rem;
    color: #2cc26a;
}

.drm__categorySelect .css-i4bv87-MuiSvgIcon-root {
    background-image: url(../../Assets/Images/downArrow.png);
    color: #808080 !important;
    font-family: "Roboto" !important;
    font-weight: 400;
    font-size: 0.731rem !important;
    background-size: 16px;
    top: 2px;
    width: 1.5em;
    height: 1.5em;
    position: inherit;
}

.drm__categorySelect .css-i4bv87-MuiSvgIcon-root path {
    display: none !important;
}

.drm__categorySelect .MuiAutocomplete-tag svg {
    display: none;
}

.drm__createMeetingButton{
    padding: 0 !important;
    width: 100%;
    background-color: #3901FF !important;
    color: white !important;
    font-size: 13px !important;
    height: 1.8rem;
    margin-right: 10px;
}

.drm__updateButton {
    padding: 0 !important;
    width: 100%;
    background-color: #2cc26a !important;
    color: white !important;
    font-size: 13px !important;
    height: 1.8rem;
    margin-right: 10px;
}

