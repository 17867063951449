.myWss__saveButtonYes {
    width: 25%;
    height: 1.875rem;
    left: 6.313rem;
    top: 23.25rem;
    margin-bottom: 0.625rem;
    color: #ffffff;
    background: #2cc26a;
    border-radius: 0.313rem;
    border: 1px;
}

.myWss__saveButtonNo {
    width: 25%;
    height: 1.875rem;
    left: 6.313rem;
    top: 23.25rem;
    margin-bottom: 0.625rem;
    color: #ffffff;
    background: red;
    border-radius: 0.313rem;
    border: 1px;
}

.myWss__saveBox {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
}

