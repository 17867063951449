.insightsAndQueries{
  padding: 0.625rem 1rem !important;
}

.insightsAndQueries__box{
    height: 20rem;
    overflow: scroll;
    margin-right: -10px;
}

.insightsAndQueries__heading{
    padding: 0.625rem 0rem;
    color:#3901FF;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 0.625rem 1rem;
}

.insightsAndQueries__table{
    border-spacing: 1.875rem;
    width: -webkit-fill-available;
    /* margin: 0.938rem  0.938rem 1rem  0.938rem ; */
    padding: 0.625rem;
    border-radius: 0.5rem;
    box-shadow: 0.078rem 0.078rem 0.334rem rgba(20, 20, 20, 0.07);
}

.insightsAndQueries__theadRow{
    text-align: center;
    height: 2.813rem;
    font-style: normal;
    font-weight: 500;
    font-size: 0.627rem;
    line-height:0.75rem

}

.insightsAndQueries__theadRowHeading{
    background: #D8D9DA;
    border-radius: 0.35rem 0.35rem 0 0;
    border-width: 0 3px !important;
    border-color: white;
}

.insightsAndQueries__table td{
    border-width: 0 0.188rem !important;
    border-color:  #F5F5F5 !important;
}

.insightsAndQueries__table tbody{
    background: #fff;
}

.insightsAndQueries__tbodyDataRow{
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 0.731rem;
    line-height:0.688rem;
}

.insightsAndQueries__tbodyTableData{
    padding: 0.3rem;
    font-size: 10px;
    font-weight: 400;
}


@media (min-width:425px){
    .insightsAndQueries__theadRow{
        font-size:0.75rem;
    }

    .insightsAndQueries__tbodyDataRow{
        
      font-size: 0.875rem
    }
}
.insightsAndQueries__tbodyDataRow input[type='checkbox'] {
    width: 13px;
    border-color: black !important;
    margin-left: -15px !important;
  }
.insightsAndQueries__tbodyDataRow input[type='checkbox'] + label {
    margin-top: 3px;
  }
.insightsAndQueries__tbodyDataRow input[type='checkbox']:checked{
    background-color: #2CC26A !important;
    border-color: rgba(197, 255, 221, 0.35) !important;
     }
.insightsAndQueries__tbodyDataRow input[type='checkbox']:focus{
    box-shadow: none !important;
    border-color: black !important;
  }

.tr_hightlight{
    background: rgba(197, 255, 221, 0.35);
}
.insightsAndQueries__table .form-check{
    padding-left: 2em;
}