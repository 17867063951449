.drm-summary {
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    /* height: 70vh; */
   
}


.drm-summary h2 {
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
}

.drm-table {
    width: 100%;
}

.drm-table thead{
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    margin-top: 30px;
    background: #ffffff;
    /* box-shadow: 1.24606px 1.24606px 5.3442px rgba(20, 20, 20, 0.07); */
    border-radius: 2.76902px;
}

.drm-table tbody{
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    margin-top: 30px;
    background: none;
    /* box-shadow: 1.24606px 1.24606px 5.3442px rgba(20, 20, 20, 0.07); */
    border-radius: 2.76902px;
}
.status-approved {
    color: green;
    font-weight: 600;
    display: flex;
    justify-content: center;
}
.status-rejected {
    color: red;
    font-weight: 600;
    display: flex;
    justify-content: center;
}
.status-pending{
    color: #959503; 
    font-weight: 600;
}

.drm-table th, .drm-table td {
    padding: 5.5px;
    text-align: center;
    /* border: 1px;  */
   
   
    font-size: 0.741rem ;
}
.DateOfMeet {
    font-size : 0.69rem !important;  
}
.meetingID {
    padding: 4px !important;
}

.drm-table td a {
    font-size: 0.581rem !important; 
}

.drm-table th {
    background-color: #f2f2f2;
    background: #ffffff;
    /* box-shadow: 1.24606px 1.24606px 5.3442px rgba(20, 20, 20, 0.07); */
    border-radius: 2.76902px;
    font-size: 0.7rem !important;
    text-align: center;
    padding: 6px !important;
}

.add-button {
    position: fixed;
    bottom: 80px;
    right: 20px;
}

.add-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #ffffff;
    color: blue;
    font-size: 24px;
    border: none;
    cursor: pointer;
}

.add-button button:hover {
    background-color: #0056b3;
}

.feedback-button {
    background-color: #007bff;
    color: white;
    border: none;
    /* padding: 2px 18px; */
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;
}

.feedback-button:hover {
    background-color: #0056b3;
}

/* styles.css */
.plus-button {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 60px;            /* Set the width of the button */
    height: 60px;           /* Set the height of the button */
    border-radius: 50%;     /* Make it a circle */
    background-color: blue;; /* Background color of the circle */
    border: none;           /* Remove default border */
    color: white;           /* Text color */
    font-size: 64px;        /* Font size of the "+" sign */
    line-height: 40px;      /* Center the "+" sign vertically */
    text-align: center;     /* Center the "+" sign horizontally */
    cursor: pointer;        /* Cursor style on hover */
    display: flex;          /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center;
    font-weight: bold; 
    padding-bottom: 8px;
       /* Center vertically */
  }
  
  .plus-button::before {
    content: "+";           /* Content of the button */
  }
  
  .plus-button:hover {
    background-color: #0056b3; /* Darker background color on hover */
  }
  
  .plus-button:focus {
    outline: none;          /* Remove focus outline */
  }

  .feedbackWrapper {
    display: flex;
  }
 .feedbackWrapper {
    font-size: larger;
 }
.feedbackWrapper p{
    color: red;
    text-align: justify;
  }
.summary_Drm tr{
   border: 1px solid rgba(0, 0, 0, 0.1); 
}  

.summary_Drm {
    border: 1px solid rgba(0, 0, 0, 0.1); 
}
  
