/* Menu Button for components */
.sidebar_MenuContainer {
  width: 6.25rem;
  display: flex;
  justify-content: center;
  padding: 15px;
}

.sidebar__menuIcon {
  color: #2b2b2b;
  margin-right: 0.625rem;
  vertical-align: middle;
}

.sidebar__menuIconActive {
  color: rgb(57, 1, 255);
  margin-right: 0.625rem;
  vertical-align: middle;
}

/* Outer container for components */

.sidebar__menu {
  background-color: #fff;
  width: 100% !important;
  padding: 0rem !important;
  height: 100%;
  position: fixed;
  top: 0;
  left: -102%;
  transition: 850ms;
  z-index: -1;
  box-shadow: 2px 0px 0px rgb(0 0 0 / 50%);
}

@media (min-width: 430px) {
  .sidebar__menu {
    width: 20.188rem !important;
  }
}

.sidebar__menuActive {
  left: 0;
  transition: 350ms;
}

.sidebar__menu ul {
  list-style-type: none;
  padding: 0rem;
  margin: 0rem 0rem 3rem 0rem;
}

/* Header for components */

.sidebar__header {
  width: 100%;
  background: #3901ff;
  height: 6rem;
  padding: 1.25rem;
}

.sidebar__userIconBox {
  text-align: center;
  align-self: center;
}

.sidebar__userIcon {
  border-radius: 50%;
  background-color: #fff;
  /* border: 2px solid #fff; */
  height: 3.5rem;
  width: 3.5rem;
}

.sidebar__header h5 {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 1.121rem;
  line-height: 1.131rem;
  color: #f9f9f9;
}

.sidebar__header p {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 0.857rem;
  line-height: 1rem;
  margin: 0rem;
  color: #f9f9f9;
}

.sidebar__locationIcon {
  color: #f9f9f9;
  vertical-align: middle;
  margin-right: 0.313rem;
  height: 0.8em;
}

/* Menu for components */

.sidebar__mainBox {
  height: 80%;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.sidebar__mainBox a {
  color: #2b2b2b;
  text-decoration: none;
}

.sidebar__headerTitle {
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 1.063rem;
  line-height: 1.25rem;
  color: #979797;
  padding: 1.25rem;
}

.sidebar__tabs {
  padding: 0rem;
  margin: 0rem;
}

.sidebar__tabBox {
  height: 2.938rem;
  background: #fff;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 0.96rem;
  line-height: 1.188rem;
  color: #2b2b2b;
  padding: 0.938rem 2rem;
}

.sidebar__tabBox:hover {
  background-color: rgba(90, 234, 149, 0.2);
  color: #3ae07e;
}

.sidebar__tabBox2:hover {
  background-color: rgba(90, 234, 149, 0.2) !important;
  color: #3ae07e;
}

.sidebar__tabBoxIcon {
  color: #2b2b2b;
  margin-right: 0.625rem;
  vertical-align: middle;
}

.sidebar__tabImgIcons {
  height: 1em;
  width: 1em;
  color: #2b2b2b;
  margin-right: 0.625rem;
}

.sidebar__helpBox {
  text-align: center;
  padding: 1.25rem 2rem;
  border-top: 1px solid #e6e6e6;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 12.3603px;
  line-height: 1.188rem;

  color: #adadad;
}

.sidebar__mainBox p {
  margin: 0rem;
}

/* Submenu */

.Sidebar__accordianItem {
  border: none !important;
}

.Sidebar__accordianItem a {
  color: #2b2b2b;
  text-decoration: none;
}

.sidebar__accordianHeader button {
  height: 2.938rem;
  background: #fff;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 0.96rem;
  line-height: 1.188rem;
  color: #2b2b2b;
  padding: 0.938rem 2rem !important;
}

.sidebar__accordianHeader button::after {
  display: none !important;
}

.sidebar__accordianHeader button:not(.collapsed) {
  color: #2b2b2b;
  background-color: #fff;
  box-shadow: none;
}

.sidebar__accordianHeader button:focus {
  z-index: 3;
  border-color: #fff;
  outline: 0;
  box-shadow: none;
}

.sidebar__accordianBody {
  padding: 0rem !important;
}

.sidebar__accordianBody ul {
  list-style-type: none;
  padding: 0rem;
  margin: 0rem;
}

.sidebar__subMenuTab {
  height: 2.938rem;
  background: #fff;
  font-family: "Maven Pro";
  font-style: normal;
  font-weight: 500;
  font-size: 0.96rem;
  line-height: 1.188rem;
  color: #2b2b2b;
  padding: 0.938rem 3.625rem;
}

.Sidebar__accordianItem a:hover {
  color: #3ae07e;
}

.sidebar__accordianHeader button:hover {
  background-color: rgba(90, 234, 149, 0.2);
  color: #3ae07e;
}

.sidebar__accordianBody li:hover {
  background-color: rgba(90, 234, 149, 0.2);
  color: #3ae07e !important;
}

.sidebar__activeTab {
  background-color: rgba(90, 234, 149, 0.2);
  color: #3ae07e;
}

/* .sidebar__activeTabIcon{
  color: #3AE07E;
} */

/* helpBox */

.sidebar__helpBox a {
  color: #2b2b2b;
  text-decoration: none;
}

.sidebar__helpBoxIcon {
  color: #adadad;
  margin-right: 0.625rem;
  vertical-align: middle;
}
.sidebar__marketWorking {
  min-width: 20px !important;
  min-height: 20px !important;
  margin-left: -4px;
}
