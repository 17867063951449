.myDealer__outerBox {
  width: auto;
  padding: 0.625rem 1rem;
  border-radius: 0.5rem;
  align-items: center;
}

.myDealer__heading {
  padding: 0.625rem 0rem;
  color: #3901ff;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
}

.myDealer__categorySelect {
  background-image: url(../../../Assets/Images/downArrow.png) !important;
  border: 0.043rem solid #808080 !important;
  border-radius: 0.3rem;
  color: #808080 !important;
  font-family: "Roboto" !important;
  font-weight: 400;
  font-size: 0.731rem !important;
  margin-bottom: 0.6rem;
  height: 1.7rem !important;
}

.myDealer__subCategorySelect {
  margin-top: 0.375rem;
}

.myDealer__defaultOption {
  display: none;
}

.myDealer__navButton {
  background: #2cc26a !important;
  border-radius: 2.76803px !important;
  border-color: #2cc26a !important;
  font-family: "Roboto" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 9.68811px !important;
  color: #f9f9f9 !important;
  line-height: 0.8rem !important;
  white-space: nowrap !important;
  width: 100% !important;
  padding: 4px !important;
}

.myDealer__categorySelect:focus {
  border-color: #2cc26a !important;
  outline: 0;
  box-shadow: none !important;
}
.myDealer__googleMap_Box {
  height: 14.5rem;
  width: 100%;
  background: #ededed;
  border-radius: 6.92008px;
  margin-top: 1rem;
  overflow: auto;
  padding: 0 !important;
}

/* loader css */
/* .parentDisable {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3) !important;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.overlay-box {
  position: absolute;
  top: 41% !important;
  left: 40% !important;
}

.overlay-box img {
  position: fixed;
  top: 43% !important;
  left: 43% !important;
  width: 4rem !important;
  z-index: 1200;
}


.hideLoader {
  display: none;
} */

/* for desktop */

/*  loader --> top-47%, left-44%  and image --> top 50%, left- 45% */

@media screen and (min-device-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
  .overlay-box {
    position: absolute;
    top: 48% !important;
    left: 44% !important;
  }

  .overlay-box img {
    position: fixed;
    top: 50% !important;
    left: 45% !important;
    width: 4rem !important;
    z-index: 1200;
  }
}

.myDealer__switchButton{
  font-size: 13px;
  font-weight: 500;
  align-self: center;
  width: 100%;
  text-align: end;
  color: #3901ff;
  /* width: 2.2em;
  height: 1.2em; */
}

.myDealer__switchOuterBox{
  display: flex;
}

.myDealer__switchBox{
  align-self: center;
  padding: 0px !important;
}

.myDealer__switchBox input{
  margin-left: 5px !important;
  width: 2.2em;
  height: 1.2em;
  /* border: 1px solid #3901ff; */
  /* border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(128, 128, 253, .25); */
  /* background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%2386b7fe%27/%3e%3c/svg%3e); */
}


