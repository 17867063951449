.pastSrInsights {
  margin: 0rem 1rem 0rem 1rem;
}

.pastSrInsights__tabs {
  font-size: 0.5rem;
  text-align: center;
  width: 100%;
}

.pastSr__alerts {
  border-left: 3px solid #FF4457;
  background: rgba(255, 68, 87, 0.13);;
  margin-bottom: 0.5rem !important;
  text-align: left !important;
  min-height: 1.644rem;
  max-height: 2rem;
  padding-top: 7px;
}

.pastSr__alerts span{
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 10.92008px;
  line-height: 8px;
  color: #2B2B2B;
  margin-left: 10px;
}

.pastSrInsights__alerts {
  border-left: 3px solid #8BC0FF;
  background: rgba(139, 192, 255, 0.13);
  margin-bottom: 0.5rem !important;
  text-align: left !important;
  min-height: 1.644rem;
  max-height: 2rem;
  padding-top: 7px;
}

.pastSrInsights__alerts span{
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 10.92008px;
  line-height: 8px;
  color: #2B2B2B;
  margin-left: 10px;
}

.pastSrInsights__label {
  color: #3901ff;
  font-weight: 600;
  margin-top: 1rem;
}

.pastSrInsights__userDetail {
  font-weight: 500;
  font-size: 14px;
  color: #8d8b8b;
}

.pastSrInsights__filterBtn {
  color: #3901ff !important;
  background: none !important;
  border: none !important;
  font-size: 14px !important;
  margin-top: -6px;
  padding: 0 !important;
}

.pastSrInsights__titleSearch {
  display: grid;
  grid-template-columns: 72% 28%;
}

.pastSrInsights__userDetailCount {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pastSrInsights__searchBox {
  display: grid;
  grid-template-columns: 1.3rem 5rem 0.7rem;
  height: 18px;
  width: 5rem;
  border: 0.7px solid #bcbcbc;
  border-radius: 2.5px;
  margin-top: 1rem;
  justify-self: right;
}

.pastSrInsights__searchBox [type="search"] {
  margin: -6px 0px 0px -6px;
}

.pastSrInsights__searchIcon {
  position: relative;
  width: 10px;
  height: 10px;
  left: 8px;
  top: 3px;
  color: #bcbcbc;
}

.pastSrInsights__filterIcon {
  margin-right: 5px;
}

.pastSrInsights .dropdown-toggle::after {
  content: none !important;
}

.pastSrInsights__categoryTitle {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 0.3rem;
}

.pastSrInsights__categoryOptions {
  font-size: 10px;
  font-weight: 500;
  display: grid;
  grid-template-columns: 25% 38% 37%;
  margin-bottom: 0.5rem;
}

.pastSrInsights__filterDropDown {
  margin: 0px 10px 0px 10px;
}

.pastSrInsights__filterButton {
  border: none;
  background: none;
  white-space: nowrap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #2b2b2b;
}

.pastSrInsights__filterButtonActive{
    border: none;
    background: none;
    white-space: nowrap;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #3901ff;
}

.pastSrInsights__sortTitle {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 0.3rem;
}

.pastSrInsights__sortTypes {
  font-size: 10px;
  font-weight: 500;
  display: grid;
  grid-template-rows: 12px 12px;
  grid-template-columns: 13% 37% 13% 37%;
  grid-row-gap: 15px;
  align-items: center;
  margin-top: 0.5rem;
}

.pastSrInsights .dropdown-menu {
  --bs-dropdown-min-width: 12rem;
}

.pastSrInsights a {
  color: #2b2b2b;
  text-decoration: none;
}

.pastSrInsights a:hover {
  color: #3901ff;
  text-decoration: none;
}

.pastSrInsights a:focus {
  color: #3901ff;
  text-decoration: underline;
}
