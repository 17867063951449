.myTeam__outerBox {
    width: auto;
    padding:0.625rem 1rem;
    border-radius: 0.5rem;
    align-items: center;
  }

.myTeam__heading{
    padding: 0.625rem 0rem;
    color:#3901FF;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
}

.myTeam__innerBoxRow{
    margin: 0 !important
}

.myTeam__search{
    border: 0.043rem solid #BCBCBC !important;
    border-radius: 0.125rem !important;
}

.myTeam__searchIcon{
    position: absolute;
    width: 2.735rem;
    color: #BCBCBC;
}

.myTeam__searchInput{
    border: none;
    padding-left: 1.313rem !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.694rem;
    color: #BCBCBC !important;
}

.myTeam__btn{
    padding-right: 0rem !important;
}

.myTeam__insightBtn{
    background: #2CC26A !important;
    border-color: #2CC26A !important;
    border-radius:0.173rem !important ;
    color: #F9F9F9 !important;
    height: 2rem;
    width: 100%;
    font-family: 'Roboto' !important;
    font-weight: 400 !important;
    font-size:0.668rem!important;
    line-height:0.688rem;
    white-space: nowrap;
}


.myTeam__dataBox{
    margin-top:0.75rem;
    width: 100%;
    background: #EDEDED;
    border-radius: 0.433rem;
    border: none;
    height: 6.25rem;
}

.visitDetails__mainBox {
    width: 100%;
    /* height: 5.5rem; */
    background: #ffffff;
    box-shadow: 0.081rem 0.081rem 0.348rem rgba(20, 20, 20, 0.07);
    border-radius: 0.525rem;
    /* margin: 0.625rem 0rem; */
    padding: 15px;
  }