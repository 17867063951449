.report-style-class{
    height: 52vh;
    margin-right: -15px;
  }

  .report-style-class::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }

  .Pbi__Box{
    /* height: 70vh; */
    width: 100%;
    background: #ffffff;
    box-shadow: 0.081rem 0.081rem 0.348rem rgb(20 20 20 / 7%);
    border-radius: 0.525rem;
    overflow-y: auto;
    padding: 10px 0px 0px 10px;
    margin-right: -10px;
    margin-top: 10px;
  }

  .pbi_dashboard .Pbi__Box{
      height: 70vh;
  }

  .pbi_dashboard .Pbi__Box iframe{
      height: 130% !important;
  }