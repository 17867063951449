.submittedLarge{
  font-size: 3rem;
  color: #FFA600;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.submittedSmall{
  font-size: 1.5rem;
  color: #FFA60033;
  margin-top: 1rem;
}

.editFormModal__formData{
    max-height: 65vh;
    overflow: overlay;
}
  
.editFormModal__formData label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #808080;
}

.editFormModal__formData option {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 9.68811px;
  line-height: 11px;
  color: #808080;
}

.editFormModal__input {
  width: 100%;
  white-space: nowrap;
  height: 1.7rem;
  margin-bottom: 0.8rem;
  border: 0.692008px solid #2cc26a;
  border-radius: 2.76803px;
  color: #808080;
  padding: 0px 5px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 9.68811px;
  line-height: 11px;
  color: #2cc26a;
}

.editFormModal__disabledClass{
  display: none;
}

.editFormModal__cancel {
  width: 4.5rem;
  background: #3901ff !important;
  border: none !important;
  height: 1.7rem;
  place-self: center;
  justify-self: right;
  padding: inherit !important;
}
.editFormModal__cancelBtn {
  font-size: 11.5px !important;
  margin-top: 0.3rem !important;
  color: #fff !important;
}

@media (max-width: 425px) {
  .editFormModal .modal-dialog {
    max-width: var(--bs-modal-width);
    margin: 0rem 1rem !important;
  }
}

.editFormModal__submitBtnBox {
  display: flex;
  justify-content: center;
}

.editFormModal__submit {
  width: 4.5rem;
  background: #fff !important;
  border: none !important;
  height: 1.7rem;
  place-self: center;
  justify-self: right;
  padding: inherit !important;
  margin-left: 15px;
}

.editFormModal__submitBtn {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #3901ff;
  margin: 0px;
  align-self: center;
}

.editFormModal__lable{

}