.dealerTimeline {
  padding: 0.625rem 1rem !important;
}

.DealerTimeline__setRange{
  color: grey;
  font-size: 15px;
}

.DealerTimeline__headingBox {
  display: flex;
  padding: 0.625rem 0rem;
  justify-content: space-between;
}

.DealerTimeline__heading {
  font-style: normal;
  font-weight: 600;
  font-size: 1.011rem;
  line-height: 1.188rem;
  color: #3901ff;
}

.css-1tfve6q-MuiSlider-mark {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
  color:  #B3B4B6 !important;
}

.css-187mznn-MuiSlider-root{
  color: #2CC26A !important;
}
