  .monthlyTour {
    width: 94%;
    margin:  0.625rem 1rem 1.625rem 1rem;
    height: 10rem;
    top: 22.688rem;
    background: #ffffff;
    box-shadow:0.06rem 0.06rem 0.259rem rgba(20, 20, 20, 0.07);
    border-radius: 0.671rem;
  }
  
  .monthlyTour__header {
    margin-left: 0 !important;
    width: 100%;
    height: 2.125rem;
    left:1.25rem;
    top: 22.688rem;
    background: #3901ff;
    border-radius: 0.671rem 0.671rem 0rem 0rem;
  }
  
  .monthlyTour__title {
    text-align: left;
    padding-left:1.188rem;
    height:0.813rem;
    left: 2.122rem;
    top: 23.375rem;
    font-style: normal;
    font-weight: 600;
    font-size: 0.688rem;
    line-height: 2rem;
    color: #ffffff;
  }
  
  .monthlyTour__label {
    font-style: normal;
    font-weight: 500;
    font-size:0.563rem;
    line-height: 2rem;
    text-align: end;
    color: #ffffff;
  }
  @media (max-width:320px){
    .monthlyTour__label {
      font-size:0.463rem;
      text-align: start;
    }
}
  .label__box1::before {
    content: "";
    position: absolute;
    width: 0.375rem;
    height: 0.375rem;
    margin-top: 0.8rem;
    background: #ffa600;
  }

  .label__box2::before {
    content: "";
    position: absolute;
    width: 0.375rem;
    height: 0.375rem;
    margin-top: 0.8rem;
    background: #FFA600;
    }

    .label__box3::before {
      content: "";
      position: absolute;
      width: 0.375rem;
      height: 0.375rem;
      margin-top: 0.8rem;
      background: #28B963;
      }

  .monthlyTour_labelMark{
    padding-left: 0.75rem;
    padding-right: 0.625rem;
  }

 .monthlyTour__days{
   display: flex;
  }

.monthlyTour__15day{
   font-weight: 600;
   color: #3901FF;
   font-size: 0.527rem;
   margin: 0.438rem;
}

.monthlyTour__dashed{
  flex: 1;
  margin: 0.85rem;
  margin-left: 0rem;
  border: none;
  height: 0.044rem;
  background:repeating-linear-gradient(90deg,#3901FF 0 0.625rem,#0000 0 0.75rem) ;
}