.divisionGroup__form{
  text-align: left;
}
.divisionGroup__input {
    width: 100%;
    white-space: nowrap;
    height: 1.7rem;
    margin-bottom: 0.8rem;
    border: 0.692008px solid #2cc26a;
    border-radius: 2.76803px;
    color: #808080;
    padding: 0px 5px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 9.68811px;
    line-height: 11px;
    color: #2cc26a;
  }

  .divisionGroup__submitBtnBox {
    display: flex;
    justify-content: center;
  }

  .divisionGroup__cancel {
    width: 4.5rem;
    background: #3901ff !important;
    border: none !important;
    height: 1.7rem;
    place-self: center;
    justify-self: right;
    padding: inherit !important;
  }
  .divisionGroup__cancelBtn {
    font-size: 11.5px !important;
    margin-top: 0.3rem !important;
    color: #fff !important;
    text-align: center;
  }

  .divisionGroup__validation{
    color:red
  }

  .divisionGroup__defaultOption{
    outline: none !important; 
    box-shadow: none !important;
  }