.myWssFeedbackForm{

}

.myWssFeedbackForm__MainBox{
    height: 73vh;
    max-height: 100vh;
    overflow-y: scroll;
}

.myWssFeedbackForm__error{
    color: red;
    font-size: smaller;
    margin-bottom: 0.5rem;
}

.myWssFeedbackForm__title {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 1.875rem;
    color: #3901ff;
}

.myWssFeedbackForm__Label{
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 10.51625px;
    margin-bottom: 0;
    padding: 5px 5px;
    overflow: hidden;
    min-width: 60px;
}
    
.myWssFeedbackForm__input {
    width: 100%;
    white-space: nowrap;
    height: 1.7rem;
    margin-bottom: 0.8rem;
    border: 0.692008px solid #2cc26a;
    border-radius: 0.3rem;
    color: #2cc26a;
    padding: 0px 5px;
}

.myWssFeedbackForm__input:focus-visible {
    outline: #2cc26a auto 1px;
}

.myWssFeedbackForm__Option{
    color: black;
    font-size: 0.6rem;
    border-bottom: 1px solid black;
    text-decoration: underline !important;
}

.myWssFeedbackForm__defaultOption {
    display: none;
}


.myWssFeedbackForm__cancel {
    width: 4.5rem;
    background: #3901ff !important;
    border: none !important;
    height: 1.7rem;
    place-self: center;
    justify-self: right;
    padding: inherit !important;
}

.myWssFeedbackForm__cancelBtn {
    font-size: 11.5px !important;
    margin-top: 0.3rem !important;
    color: #fff !important;
}

@media (max-width: 425px) {
    .editFormModal .modal-dialog {
        max-width: var(--bs-modal-width);
        margin: 0rem 1rem !important;
    }
}

.myWssFeedbackForm__submitBtnBox {
    display: flex;
    justify-content: center;
}

.myWssFeedbackForm__submit {
    width: 4.5rem;
    background: #fff !important;
    border: none !important;
    height: 1.7rem;
    place-self: center;
    justify-self: right;
    padding: inherit !important;
    margin-left: 15px;
}

.myWssFeedbackForm__submitBtn {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #3901ff;
    margin: 0px;
    align-self: center;
}

.myWssFeedbackForm__downloadBtn {
    background: #2CC26A !important;
    border-radius: 0.3rem !important;
    border-color: #2CC26A !important;
    color: #F9F9F9 !important;
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 0.65rem !important;
    white-space: nowrap;
    float: right;
}