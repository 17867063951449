.MarketVisitTabs table {
  table-layout: fixed;
}
.MarketVisitTabs table td, .MarketVisitTabs table th {
  word-wrap: break-word;
  text-align: center;
}

.MarketVisitTabs__Text{
    padding: 0.625rem;
    font-weight: 500;
    font-size: 0.6rem;
    color: #2b2b2b;
    vertical-align: middle;
    font-family: "Maven Pro";
    padding: 5px !important;
  }

  .MarketVisitTabs__addBtn {
    background: #2cc26a !important;
    border-radius: 0.3rem !important;
    border-color: #2cc26a !important;
    color: #f9f9f9 !important;
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 0.606rem !important;
    line-height: 0.8rem;
    white-space: nowrap;
    width: 50%;
    padding: 2px !important;
  }

  .MarketVisitTabs__deleteBtn {
    background: #d2112c !important;
    border-radius: 0.3rem !important;
    border-color: #d2112c  !important;
    color: #f9f9f9 !important;
    font-family: "Roboto" !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 0.606rem !important;
    line-height: 0.8rem;
    white-space: nowrap;
    width: 50%;
    padding: 2px !important;
  }

  .MarketVisitTabs {
    width: 100%;
    margin-top: 1rem;
  }
  
  .MarketVisitTabs__mainbox {
    background: #ffffff;
    box-shadow: 0.075rem 0.075rem 0.334rem rgba(20, 20, 20, 0.07);
    border-radius: 0.3rem;
  }
  
  .MarketVisitTabs__heading {
    padding: 0.625rem 0px;
    color: #3901FF;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
  }
  
  .MarketVisitTabs__outerInputBox {
    width: auto;
    padding: 0.625rem;
    border-radius: 0.5rem;
    align-items: center;
  }
  
  .MarketVisitTabs__outerInputBox p {
    margin: 0px;
    color: #EF5464;
    font-size: 0.60rem;
    padding: 5px 0px;
    white-space: nowrap;
  }
  
  .MarketVisitTabs__heading {
    padding: 0.625rem 0px;
    color: #3901ff;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .MarketVisitTabs__textHeader{
    font-size: 10px;
  }